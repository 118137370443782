	
import { B_REST_Utils } from "@/bREST/core/classes";



export default class CC_Utils
{
	static get BRANDS() { return CC_Utils._BRANDS; }
		/*
		IMPORTANT: Don't change declaration order, otherwise it'll complicate some regexes (ex visa coming after visaelectron for a reason)
		Note that there also other brands, but we don't have their regexs
		IMPORTANT: If ever we add new ones, also need to add them in ./icons/brands/*.png
		*/
		static _BRANDS = {
			UNKNOWN: {
				tag:               "unknown",
				cvvLength:         3,
				mask:              "#### #### #### ####",
				regex_full:        /^\d{16}$/,
				regex_firstDigits: null,
				ex:                "0000 0000 0000 0000",
			},
			VISAELECTRON: {
				tag:               "visaelectron",
				cvvLength:         3,
				mask:              "#### #### #### ####",
				regex_full:        /^4(026|17500|405|508|844|91[37])/,
				regex_firstDigits: /^4(026|175|405|508|844|91[37])/,
				ex:                "4026 1111 1111 1111",
			},
			VISA: {
				tag:               "visa",
				cvvLength:         3,
				mask:              "#### #### #### ####",
				regex_full:        /^4\d{12}(\d{3})?$/,
				regex_firstDigits: /^4\d{3}/,
				ex:                "4111 1111 1111 1111",
			},
			ELO: {
				tag:               "elo",
				cvvLength:         3,
				mask:              "#### #### #### ####",
				regex_full:        /^(509091|636368|636297|504175|438935|40117[8-9]|45763[1-2]|457393|431274|50990[0-2]|5099[7-9]\d|50996[4-9]|509[1-8]\d\d|(5090(0[0-2]|0[4-9]|1[2-9]|[24589]\d|3[1-9]|6[0-46-9]|7[0-24-9]))|(5067(0[0-24-8]|1[0-24-9]|2[014-9]|3[0-379]|4\d|5[0-3]|6[0-5]|7[0-8]))|(6504(0[5-9]|1\d|2\d|3\d))|(6504(8[5-9]|9\d)|6505(0\d|1\d|2\d|3[0-8]))|(6505(4[1-9]|5\d|6\d|7\d|8\d|9[0-8]))|6507(0\d|1[0-8])|65072[0-7]|6509(0[1-9]|1\d|20)|6516(5[2-9]|6\d|7\d)|6550(0\d|1\d)|6550(2[1-9]|3\d|4\d|5[0-8]))/,
				regex_firstDigits: /^(4011|4312|4389|457[36]|5041|5067|509\d|636[23]|650[4579]|6516|6550)/,
				ex:                "5090 9100 0000 0000",
			},
			AMEX: {
				tag:               "amex",
				cvvLength:         4,
				mask:              "#### ###### #####",
				regex_full:        /^3[47]\d{13}$/,
				regex_firstDigits: /^3[47]\d{2}/,
				ex:                "3493 700386 56069",
			},
			MASTERCARD: {
				tag:               "mastercard",
				cvvLength:         3,
				mask:              "#### #### #### ####",
				regex_full:        /^(5[1-5]\d{4}|677189)\d{10}$/,
				regex_firstDigits: /^(5[1-5]\d{2}|6771)/,
				ex:                "5500 0000 0000 0004",
			},
			DISCOVER: {
				tag:               "discover",
				cvvLength:         3,
				mask:              "#### #### #### ####",
				regex_full:        /^6(?:011|5\d{2})\d{12}$/,
				regex_firstDigits: null,
				ex:                "6011 0000 0000 0004",
			},
			UNIONPAY: {
				tag:               "unionpay",
				cvvLength:         3,
				mask:              "###### ####### ######",
				regex_full:        /^62\d{15,18}$/,
				regex_firstDigits: /^62\d{4}/,
				ex:                "620000 0000000 000000",
			},
			TROY: {
				tag:               "troy",
				cvvLength:         3,
				mask:              "#### #### #### ####",
				regex_full:        /^9792\d{12}$/,
				regex_firstDigits: /^9792/,
				ex:                "9792 0000 0000 0000",
			},
			DINERSCLUB: {
				tag:               "dinersclub",
				cvvLength:         3,
				mask:              "#### ###### ####",
				regex_full:        /^3(0[0-5]|[68]\d)\d{11,16}/,
				regex_firstDigits: /^3(0[0-5]|[68]\d)\d/,
				ex:                "3852 000002 3237",
			},
			JCB: {
				tag:               "jcb",
				cvvLength:         3,
				mask:              "#### #### #### ####",
				regex_full:        /(?:2131|1800|35\d{3})\d{11}$/, //WTF. Seems to mean that we have to find "2131|1800|35\d{3}" anywhere, not necessarily at beginning...
				regex_firstDigits: /(?:2131|1800|35\d{3})\d{11}$/, //WTF, so just use the full regex
				ex:                "3530 1113 3330 0000",
			},
			LASER: {
				tag:               "laser",
				cvvLength:         3,
				mask:              "#### #### #### ####",
				regex_full:        /^(6304|670[69]|6771)\d{12,15}$/,
				regex_firstDigits: /^(6304|670[69]|6771)/,
				ex:                "6304 1000 0000 0008",
			},
			DANKORT: {
				tag:               "dankort",
				cvvLength:         3,
				mask:              "#### #### #### ####",
				regex_full:        /^5019\d{12}$/,
				regex_firstDigits: /^5019/,
				ex:                "5019 0000 0000 0000",
			},
			UATP: {
				tag:               "uatp",
				cvvLength:         3,
				mask:              "#### #### #### ###",
				regex_full:        /^1\d{14}$/,
				regex_firstDigits: /^1\d{3}$/,
				ex:                "1000 0000 0000 000",
			},
			MIR: {
				tag:               "mir",
				cvvLength:         3,
				mask:              "#### #### #### ####",
				regex_full:        /^220[0-4]\d{12}$/,
				regex_firstDigits: /^220[0-4]/,
				ex:                "2204 0000 0000 0000",
			},
			HIPERCARD: {
				tag:               "hipercard",
				cvvLength:         3,
				mask:              "#### #### #### ####",
				regex_full:        /^((606282\d{10}(\d{3})?)|(3841\d{15}))$/,
				regex_firstDigits: /^(6062|3841)/,
				ex:                "6062 8200 0000 0000",
			},
			AURA: {
				tag:               "aura",
				cvvLength:         3,
				mask:              "#### #### #### ####",
				regex_full:        /^((?!504175))^((?!5067))(^50\d)/, //WTF
				regex_firstDigits: /^((?!504175))^((?!5067))(^50\d)/, //WTF, so just use the full regex
				ex:                "???? ???? ???? ????",
			},
			MAESTRO: {
				tag:               "maestro",
				cvvLength:         3,
				mask:              "#### #### #### ####",
				regex_full:        /(?:5[0678]\d\d|6304|6390|67\d\d)\d{8,15}$/, //WTF
				regex_firstDigits: /(?:5[0678]\d\d|6304|6390|67\d\d)\d{8,15}$/, //WTF, so just use the full regex
				ex:                "6304 0000 0000 0000",
			},
		};
	
	
	
	/*
	Converts all manner of card number inputs to strings w no spaces nor invalid chars. NULL becomes "".
	Ex "   123 5678 000000 00 AA " becomes "123567800000000" and 12345 becomes "12345".
	Always rets a string.
	*/
	static cleanup(cardNumber) { return (cardNumber??"").toString().replace(/\D/g,""); }

	/*
	Performs the Luhn algo on card number to check if it makes sense. https://en.wikipedia.org/wiki/Luhn_algorithm
	Note that the check doesn't include validation for CVV, because it's a random security number that's not a checksum of the cardNumber + expiration date.
	Rets bool.
	Check cleanup() for accepted cardNumber vals.
	*/
	static validateLuhn(cardNumber)
	{
		const spaceless = CC_Utils.cleanup(cardNumber);
		const lastIdx   = spaceless.length-1;
		
		let checksum = 0;
		for (let i=0; i<=lastIdx; i++)
		{
			const digit = parseInt(spaceless[lastIdx-i]);
			checksum += digit;
			if (i%2==1) { checksum += digit<5 ? digit : digit-9; }
		}
		
		return (checksum%10)===0;
	}

	/*
	Checks that CVV is 3~4 chars, optionally against a BRANDS.X.cvvLength.
	Accepts ints & strings.
	Rets bool.
	*/
	static validateCVV(cvv, expectedCVVLength=null)
	{
		if (cvv===null) { return false; }
		const length = cvv.toString().length;
		
		if (expectedCVVLength) { return length===expectedCVVLength; }
		return length===3 || length===4;
	}

	/*
	Rets one of BRANDS.X, using their regex_firstDigits search, or BRANDS.UNKNOWN when nothing matches.
	Always rets a string.
	Check cleanup() for accepted cardNumber vals.
	*/
	static brand_eval(cardNumber_orFirstDigits)
	{
		const spaceless = CC_Utils.cleanup(cardNumber_orFirstDigits);
		
		if (spaceless.length>=4) //All card brand regex_firstDigits reqs to have at least 4 chars filled
		{
			for (const loop_brandTag in CC_Utils._BRANDS)
			{
				if (loop_brandTag==="unknown") { continue; }
				const loop_brand = CC_Utils._BRANDS[loop_brandTag];
				if (spaceless.match(loop_brand.regex_firstDigits)) { return loop_brand; }
			}
		}
		
		return CC_Utils._BRANDS.UNKNOWN;
	}
	
	static _brand_assert(brand) { if(!B_REST_Utils.object_is(brand)||!brand.regex_full){B_REST_Utils.throwEx(`Expected a CC_Utils::BRANDS.X`);} }
	
	/*
	Checks if whole card number perfectly matches a brand's regex. Doesn't perform validateLuhn() check though.
	Always rets a string.
	Check cleanup() for accepted cardNumber vals.
	*/
	static brand_validate(brand, cardNumber)
	{
		CC_Utils._brand_assert(brand);
		return CC_Utils.cleanup(cardNumber).match(brand.regex_full);
	}

	/*
	Converts a card like "4111111111111111" into "4111 1111 1111 1111", against one of BRANDS.x's mask.
	If getting partial cc like "411111", would yield "4111 11", if BRANDS.visa.
	Always rets a string.
	Check cleanup() for accepted cardNumber vals.
	*/
	static brand_format(brand, cardNumber)
	{
		CC_Utils._brand_assert(brand);
		const spaceless         = CC_Utils.cleanup(cardNumber);
		const brand_mask        = brand.mask;
		const brand_mask_length = brand_mask.length;
		
		let formatted = spaceless;
		for (let i=0; i<Math.min(formatted.length,brand_mask_length); i++)
		{
			const loop_brand_char = brand_mask[i];
			if (loop_brand_char===" ") { formatted = formatted.substr(0,i)+" "+formatted.substr(i,brand_mask_length-i-1); }
		}
		return formatted.trimEnd();
	}

	/*
	If brand's mask is like "#### #### #### ####" and we inputted "123456" so far, will yield "1234 56## #### ####".
	Always rets a string.
	Check cleanup() for accepted cardNumber vals.
	*/
	static brand_mask_progress(brand, cardNumber)
	{
		CC_Utils._brand_assert(brand);
		const brandMask        = brand.mask;
		const formatted        = CC_Utils.brand_format(brand,cardNumber);
		const formatted_length = formatted.length;
		
		let masked = "";
		for (let i=0; i<brandMask.length; i++) { masked += formatted_length>i?formatted[i]:brandMask[i]; }
		return masked;
	}
	/*
	Alt when we already have -some- of the first & last digits, but not what's in the middle
	NOTE: Blindly replaces chars in place wo respecting initial mask, so if mask was like "### ### ### ###" and we provide 1234 & 5678,
			would yield "1234### ###5678" instead of "123 4## ##5 678", but for now, all have at least 4 #### on both sides, and only one like "###### ####### ######", so not a prob
	*/
	static brand_mask_firstLastDigits(brand, cardFirstDigits,cardLastDigits)
	{
		CC_Utils._brand_assert(brand);
		cardFirstDigits = cardFirstDigits.toString();
		cardLastDigits  = cardLastDigits.toString();
		const cardFirstDigits_length = cardFirstDigits.length;
		const middleMask = brand.mask.substr(cardFirstDigits_length, brand.mask.length-cardLastDigits.length-cardFirstDigits_length);
		return `${cardFirstDigits}${middleMask}${cardLastDigits}`;
	}

	//So we can directly do <img :src="CC_Utils.brand_iconPath(brand)" />. Rets NULL though if it was for CARD_BRAND.UNKNOWN, as we don't have an icon for it yet
	static brand_iconPath(brand)
	{
		CC_Utils._brand_assert(brand);
		if (brand.tag==="unknown") { return null; }
		const path = require(`@/bREST/core/implementations/vue/vuetifyComponents/creditCard/icons/brands/${brand.tag}.png`);
		return path.default || path;
	}
};

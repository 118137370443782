/*
For PWD strength validation
https://github.com/dropbox/zxcvbn
npm install zxcvbn
*/
import zxcvbn from "zxcvbn";

import B_REST_Utils                         from "../B_REST_Utils.js";
import B_REST_App_base                      from "../app/B_REST_App_base.js";
import B_REST_Descriptor                    from "./B_REST_Descriptor.js";
import B_REST_FieldDescriptor_DB_EnumMember from "./B_REST_FieldDescriptor_DB_EnumMember.js";
import B_REST_ModelFields                   from "../models/B_REST_ModelFields.js";



class B_REST_FieldDescriptor_base
{
	static get SET_ONCE_OFF()   { return false;   }
	static get SET_ONCE_NOW()   { return "now";   }
	static get SET_ONCE_LATER() { return "later"; }
	
	static get VALIDATION_FIELD_NAME_ELLIPSIS_LENGTH() { return 20; }
	
	
	_name           = null;
	_descriptor     = null;  //Optional ptr on a parent B_REST_Descriptor. Note that fields can work standalone though
	_loc            = null;  //Translated obj like {label, shortLabel, enum:{a,b,c}, ...}
	_loc_label      = null;  //The "label" prop within the obj
	_loc_shortLabel = null;  //The "shortLabel" prop within the obj, or its "label" fallback
	_isRequired     = false; //NOTE: For now, we'll only care about this in B_REST_FieldDescriptor_DB
	_isNullable     = false; //NOTE: For now, we'll only care about this in B_REST_FieldDescriptor_DB. Check notes in B_REST_ModelField_DB::_setVal() for special behavior with NULL vs ""
	_wCustomSetter  = false; //NOTE: Not relevant in frontend though; means that when we POST, server route will have to do custom code to parse this field (ex email must check that it's unique...)
	_setOnce        = B_REST_FieldDescriptor_base.SET_ONCE_OFF; //One of B_REST_FieldDescriptor_base::SET_ONCE_x
		//IMPORTANT: If we add new fields, will have impacts in B_REST_Vuetify_GenericList_Filter's constructor
	
	
	constructor(name, options={})
	{
		this._name = name;
		if (B_REST_Utils.object_hasPropName(options,"isRequired"))    { this._isRequired    = options.isRequired;    }
		if (B_REST_Utils.object_hasPropName(options,"isNullable"))    { this._isNullable    = options.isNullable;    }
		if (B_REST_Utils.object_hasPropName(options,"wCustomSetter")) { this._wCustomSetter = options.wCustomSetter; }
		if (B_REST_Utils.object_hasPropName(options,"setOnce"))       { this._setOnce       = options.setOnce;       }
		
		//For loc, either pass {label,shortLabel,enum:{a,b,c}} directly, or a path to fetch right now (generally for standalone fields)
		{
			if (B_REST_Utils.object_hasPropName(options,"locBasePath"))
			{
				if (!B_REST_Utils.string_is(options.locBasePath)) { this._throwField(`locBasePath must be a string, if specified`,options); }
				this._loc = B_REST_App_base.instance.t_custom_subTree(options.locBasePath);
				
				//If it didn't work, help the user know something is missing in the current lang
				if (!this._loc)
				{
					this._loc = {
						label: B_REST_App_base.instance.t_custom_warnNotFound(options.locBasePath),
					};
				}
			}
			else if (B_REST_Utils.object_hasPropName(options,"loc"))
			{
				if (!B_REST_Utils.object_is(options.loc)) { this._throwField(`loc must be an object, if specified`,options); }
				this._loc = options.loc;
			}
			
			//Now validate
			if (!B_REST_Utils.object_is(this._loc))
			{
				this._throwField(`Field must contain a {locBasePath} or {loc} prop in options. If it was a locBasePath, must point to an obj like {label, ...}, and not a string`,options);
			}
			if (!B_REST_Utils.object_hasPropName(this._loc,B_REST_App_base.LOC_KEY_LABEL)) { this._throwField(`Field's loc must at least have a {${B_REST_App_base.LOC_KEY_LABEL}}`,this._loc); }
			
			this._loc_label_updateCache();
		}
	}
	
	
	_throwField(msg, details=null) { B_REST_Utils.throwEx(`B_REST_FieldDescriptor_base<${this._name}>: ${msg}`, details); }
	
	
	get name()          { return this._name;          }
	get isNullable()    { return this._isNullable;    } //WARNING: Now also has to take into account B_REST_ModelField_base::forceIsRequired
	get wCustomSetter() { return this._wCustomSetter; }
	get setOnce()       { return this._setOnce;       }
	
	get isRequired() { return this._isRequired; }
	//Allow changing, ex to let Model_User's normally nullable pwd field to become req in UI
	set isRequired(val)
	{
		if (val) { this._isNullable=false; }
		this._isRequired = val;
	}
	
	get descriptor() { return this._descriptor; }
	//Links the field descriptor to a model descriptor
	set descriptor(val)
	{
		B_REST_Utils.instance_isOfClass_assert(B_REST_Descriptor, val);
		this._descriptor = val;
		this._loc_label_updateCache();
	}
	
	
	get loc() { return this._loc; }
	get label()
	{
		if (this._loc_label===null)
		{
			this._loc_label = `%${this._loc_fakeLocPath}.${B_REST_App_base.LOC_KEY_LABEL}%`;
		}
		
		return this._loc_label;
	}
	get shortLabel()
	{
		if (this._loc_shortLabel===null)
		{
			this._loc_shortLabel = `%${this._loc_fakeLocPath}.${B_REST_App_base.LOC_KEY_SHORT_LABEL}%`;
		}
		
		return this._loc_shortLabel;
	}
		get _loc_fakeLocPath() { return B_REST_App_base.t_custom_field_baseLocPath(this._descriptor?.name||"<standalone>", this._name); }
	_loc_label_updateCache()
	{
		this._loc_label      = this._loc[B_REST_App_base.LOC_KEY_LABEL]       ?? null;
		this._loc_shortLabel = this._loc[B_REST_App_base.LOC_KEY_SHORT_LABEL] ?? this._loc_label;
	}
	//To use only in B_REST_Descriptor::_commonDefs_fetch_fromServerBootResponse_updateLoc()
	updateLoc_fromNewerFieldDescriptor(fieldDescriptor_new)
	{
		B_REST_Utils.instance_isOfClass_assert(B_REST_FieldDescriptor_base, fieldDescriptor_new);
		
		this._loc = B_REST_Utils.object_copy(fieldDescriptor_new._loc, /*bDeep*/true);
		
		this._loc_label_updateCache();
		
		this._abstract_updateLoc_fromNewerFieldDescriptor(fieldDescriptor_new);
	}
		_abstract_updateLoc_fromNewerFieldDescriptor(fieldDescriptor_new) { B_REST_Utils.throwEx(`Must override base method`); }
	
	
	/*
	Usage ex:
		placeholder_translate("db.number.between", {min,max})
	*/
	placeholder_translate(tag, details={})
	{
		details.fieldName = this._name;
		
		return B_REST_App_base.instance.t_core_field_placeholder(tag, details);
	}
	
	
	
	/*
	Usage ex:
		validation_translate("db.maxLength", {maxLength,currentLength})
	WARNING:
		Translates using core loc.json, so don't use that for custom validation msgs
	*/
	validation_translate(tag, modelField=null, details={})
	{
		let label = null;
		
		if (modelField)
		{
			if (!(modelField instanceof B_REST_ModelFields.base)) { this._throwField(`Expected to receive an instance of B_REST_ModelField_base`); }
			label = modelField.label; //Takes the model field one, or falls back to the field descriptor's one
			
			if (B_REST_App_base.instance.debug_fieldNamePaths) { label += ` [${modelField.debugFieldNamePath()}]`; } //Add something like "[<Lead>/mainUser<User>/coords<Coordinate>/address]"
		}
		else { label = this.label; }
		
		details.fieldName = label;
		return B_REST_App_base.instance.t_core_field_validation(tag, details);
	}
	
	
	
	//Creates a B_REST_ModelFields.base of the right der
	factory_modelField(parentModel=null) { return this._abstract_factory_modelField(parentModel); }
		//Must ret an instance of B_REST_ModelFields.base of the right der
		_abstract_factory_modelField(parentModel=null) { B_REST_Utils.throwEx(`Must override base method`); }
};






class B_REST_FieldDescriptor_WithFuncs_base extends B_REST_FieldDescriptor_base
{
	static get FUNC_AUTO()    { return "auto";    }
	static get FUNC_MANUAL()  { return "manual";  }
	static get FUNC_CLOSURE() { return "closure"; }
	
	
	//All one of FUNC_x
	_func_load               = null;
	_func_save               = null;
	_func_toObj              = null;
	_func_fromObj            = null;
	_func_unsavedChanges_has = null;
	_func_delete_can_orTag   = null;
	_func_delete             = null;
		//WARNING: If we add loc fields here, fix _abstract_updateLoc_fromNewerFieldDescriptor()
	
	
	constructor(name, options={})
	{
		super(name, options);
		
		if (B_REST_Utils.object_hasPropName(options,"func_load"))               { this._func_load               = options.func_load;               }
		if (B_REST_Utils.object_hasPropName(options,"func_save"))               { this._func_save               = options.func_save;               }
		if (B_REST_Utils.object_hasPropName(options,"func_toObj"))              { this._func_toObj              = options.func_toObj;              }
		if (B_REST_Utils.object_hasPropName(options,"func_fromObj"))            { this._func_fromObj            = options.func_fromObj;            }
		if (B_REST_Utils.object_hasPropName(options,"func_unsavedChanges_has")) { this._func_unsavedChanges_has = options.func_unsavedChanges_has; }
		if (B_REST_Utils.object_hasPropName(options,"func_delete_can_orTag"))   { this._func_delete_can_orTag   = options.func_delete_can_orTag;   }
		if (B_REST_Utils.object_hasPropName(options,"func_delete"))             { this._func_delete             = options.func_delete;             }
	}
	
	
	get func_load()               { return this._func_load;               }
	get func_save()               { return this._func_save;               }
	get func_toObj()              { return this._func_toObj;              }
	get func_fromObj()            { return this._func_fromObj;            }
	get func_unsavedChanges_has() { return this._func_unsavedChanges_has; }
	get func_delete_can_orTag()   { return this._func_delete_can_orTag;   }
	get func_delete()             { return this._func_delete;             }
};






class B_REST_FieldDescriptor_WithFuncs_WithModels_base extends B_REST_FieldDescriptor_WithFuncs_base
{
	_modelClassName = null; //String without "Model_x". Would be helpful if it started in uppercase, ex "Contact"
		//WARNING: If we add loc fields here, fix _abstract_updateLoc_fromNewerFieldDescriptor()
	
	constructor(name, modelClassName, options={})
	{
		super(name, options);
		
		this._modelClassName = modelClassName;
	}
	
	get modelClassName() { return this._modelClassName; }
};






class B_REST_FieldDescriptor_SubModel_base extends B_REST_FieldDescriptor_WithFuncs_WithModels_base
{
	_subModel_fkFieldName = null;
		//WARNING: If we add loc fields here, fix _abstract_updateLoc_fromNewerFieldDescriptor()
	
	constructor(name, modelClassName, subModel_fkFieldName, options={})
	{
		super(name, modelClassName, options);
		
		this._subModel_fkFieldName = subModel_fkFieldName;
	}
	
	get subModel_fkFieldName() { return this._subModel_fkFieldName; }
};






class B_REST_FieldDescriptor_DB extends B_REST_FieldDescriptor_base
{
	static get TYPE_STRING()              { return "string";             }
	static get TYPE_INT()                 { return "int";                }
	static get TYPE_DECIMAL()             { return "decimal";            }
	static get TYPE_BOOL()                { return "bool";               }
	static get TYPE_JSON()                { return "json";               }
	static get TYPE_DT()                  { return "dt";                 }
	static get TYPE_D()                   { return "d";                  }
	static get TYPE_T()                   { return "t";                  }
	static get TYPE_C_STAMP()             { return "createdDT";          }
	static get TYPE_U_STAMP()             { return "updatedDT";          }
	static get TYPE_ENUM()                { return "enum";               }
	static get TYPE_PHONE()               { return "phone";              }
	static get TYPE_EMAIL()               { return "email";              }
	static get TYPE_PWD()                 { return "pwd";                }
	static get TYPE_CALC_FLAT_SEARCH()    { return "calcFlatSearch";     }
	static get TYPE_ARR()                 { return "arr";                }
	static get TYPE_MULTILINGUAL_STRING() { return "multilingualString"; }
	static get TYPE_CUSTOM()              { return "custom";             }
		static _TYPES = [
			B_REST_FieldDescriptor_DB.TYPE_STRING,
			B_REST_FieldDescriptor_DB.TYPE_INT,
			B_REST_FieldDescriptor_DB.TYPE_DECIMAL,
			B_REST_FieldDescriptor_DB.TYPE_BOOL,
			B_REST_FieldDescriptor_DB.TYPE_JSON,
			B_REST_FieldDescriptor_DB.TYPE_DT,
			B_REST_FieldDescriptor_DB.TYPE_D,
			B_REST_FieldDescriptor_DB.TYPE_T,
			B_REST_FieldDescriptor_DB.TYPE_C_STAMP,
			B_REST_FieldDescriptor_DB.TYPE_U_STAMP,
			B_REST_FieldDescriptor_DB.TYPE_ENUM,
			B_REST_FieldDescriptor_DB.TYPE_PHONE,
			B_REST_FieldDescriptor_DB.TYPE_EMAIL,
			B_REST_FieldDescriptor_DB.TYPE_PWD,
			B_REST_FieldDescriptor_DB.TYPE_CALC_FLAT_SEARCH,
			B_REST_FieldDescriptor_DB.TYPE_ARR,
			B_REST_FieldDescriptor_DB.TYPE_MULTILINGUAL_STRING,
			B_REST_FieldDescriptor_DB.TYPE_CUSTOM,
		];
		static _TYPES_WITH_BETWEEN_RANGE = [
			B_REST_FieldDescriptor_DB.TYPE_STRING,
			B_REST_FieldDescriptor_DB.TYPE_INT,
			B_REST_FieldDescriptor_DB.TYPE_DECIMAL,
			B_REST_FieldDescriptor_DB.TYPE_DT,
			B_REST_FieldDescriptor_DB.TYPE_D,
			B_REST_FieldDescriptor_DB.TYPE_T,
			B_REST_FieldDescriptor_DB.TYPE_PHONE,
			B_REST_FieldDescriptor_DB.TYPE_EMAIL,
			B_REST_FieldDescriptor_DB.TYPE_PWD,
			B_REST_FieldDescriptor_DB.TYPE_CALC_FLAT_SEARCH,
			B_REST_FieldDescriptor_DB.TYPE_MULTILINGUAL_STRING,
			B_REST_FieldDescriptor_DB.TYPE_CUSTOM,
		];
	static get INT_MAX_SIZE_BEFORE_EXPONENT_FLIP() { return 999999999999999; }
	static get DECIMAL_MAX_REPRESENTATION_LENGTH() { return 15; }
	static get PWD_MINIMUM_STRENGTH() { return 3; } //Check validation_getPwdStrengthLvl() using zxcvbn()
	//NOTE: Maybe later we could add per-field props for this, but don't think nobody cares about seconds anywhere. Only used in BrFieldDb.vue
	
	static get DT_HTML5_SEPARATOR() { return "T"; } //Between date & time
	static get DT_CARE_ABOUT_SECONDS() { return false; }
	static get T_CARE_ABOUT_SECONDS()  { return false; }
	static get TYPE_BOOL_NULL_NORMALIZED_VAL() { return null; } //NOTE: Was initially false, but not accurate when used in tristate dropdowns. Change back to false if it causes probs elsewhere
	
	_type             = null;  //One of B_REST_FieldDescriptor_DB::TYPE_x
	_isPKField        = false; //Helper to know if it's part of a B_REST_Descriptor's pk fields, no matter it's a multi-field PK or not, or AUTO_INC or not
	_optionalVal      = null;  //Only for if not required
	_min              = null;  //For numeric, string length & Date ranges
	_max              = null;  //For numeric, string length & Date ranges
	_decimals         = null;  //Only for TYPE_DECIMAL
	_enum_members     = null;  //Arr of B_REST_FieldDescriptor_DB_EnumMember instances. Can set from enum_makeEnumMembersFromPipedTagList()
	_pwd_evalStrength = null;  //For TYPE_PWD, do we want to evaluate if it's secure enough ? Should be false for when we log in
		//IMPORTANT: If we add new fields, will have impacts in B_REST_Vuetify_GenericList_Filter's constructor
	//Stuff for when this is a FK, usually bound to a B_REST_FieldDescriptor_ModelLookupRef of the same model
	_lookup_modelName = null;  //The most important thing; this is an FK to which model ?
	_lookup_fieldName = null;  //In case this field belongs to a model where we also define a B_REST_FieldDescriptor_ModelLookupRef that is bound to this one, though optional
		//IMPORTANT: If we add new fields, will have impacts in B_REST_Vuetify_GenericList_Filter's constructor
	//WARNING: If we add loc fields here, fix _abstract_updateLoc_fromNewerFieldDescriptor()
	
	
	
	/*
	NOTE: Consider using the static create_type_x(name,options) helpers
	Options:
		{
			isPKField
			optionalVal
			min
			max
			decimals
			enum_members: Either a piped string, or arr of B_REST_FieldDescriptor_DB_EnumMember instances
			pwd_evalStrength
			lookupInfo: {modelName,fieldName:null}
		}
	*/
	constructor(name, type, options={})
	{
		super(name, options);
		
		if (!B_REST_FieldDescriptor_DB._TYPES.includes(type)) { this._throwField(`Type "${type}" isn't a valid B_REST_FieldDescriptor_DB::TYPE_x`); }
		this._type = type;
		
		if (B_REST_Utils.object_hasPropName(options,"isPKField"))   { this._isPKField   = options.isPKField;   }
		if (B_REST_Utils.object_hasPropName(options,"optionalVal")) { this._optionalVal = options.optionalVal; }
		if (B_REST_Utils.object_hasPropName(options,"min"))         { this._min         = options.min;         }
		if (B_REST_Utils.object_hasPropName(options,"max"))         { this._max         = options.max;         }
		if (B_REST_Utils.object_hasPropName(options,"decimals"))    { this._decimals    = options.decimals;    }
		
		if (B_REST_Utils.object_hasPropName(options,"pwd_evalStrength"))
		{
			if (!this.type_is_pwd) { this._throwField(`Can only define pwd_evalStrength for TYPE_PWD`); }
			
			this._pwd_evalStrength = options.pwd_evalStrength;
		}
		else if (this.type_is_pwd) { this._pwd_evalStrength=true; }
		
		let options_enum_members = options.enum_members;
		if (options_enum_members)
		{
			//Accept either a piped list, or an arr of B_REST_FieldDescriptor_DB_EnumMember instances, in case we want to pass extraData by ourselves
			if (B_REST_Utils.string_is(options_enum_members))
			{
				options_enum_members = options_enum_members.split("|").map(loop_tag => new B_REST_FieldDescriptor_DB_EnumMember(loop_tag));
			}
			else { B_REST_Utils.array_isOfClassInstances_assert(B_REST_FieldDescriptor_DB_EnumMember,options_enum_members); }
			
			this._enum_members = options_enum_members;
			
			//Assign loc for each enum member
			{
				if (!B_REST_Utils.object_hasPropName(this._loc,B_REST_App_base.LOC_KEY_ENUM_TAGS)) { this._throwField(`Field's loc must at least have a {${B_REST_App_base.LOC_KEY_ENUM_TAGS}}`,this._loc); }
				const loc_enumTags = this._loc[B_REST_App_base.LOC_KEY_ENUM_TAGS];
				if (!B_REST_Utils.object_is(loc_enumTags)) { this._throwField(`Field's {${B_REST_App_base.LOC_KEY_ENUM_TAGS}} loc must be an obj`,this._loc); }
				
				for (const loop_enumMember of this._enum_members)
				{
					const loop_enumMember_tag = loop_enumMember.tag;
					if (!B_REST_Utils.object_hasPropName(loc_enumTags,loop_enumMember_tag)) { this._throwField(`Field's loc must at least have a {${B_REST_App_base.LOC_KEY_ENUM_TAGS}:{${loop_enumMember_tag}}}`,this._loc); }
					
					loop_enumMember.label = loc_enumTags[loop_enumMember_tag];
				}
			}
		}
		
		const lookupInfo = options.lookupInfo;
		if (lookupInfo)
		{
			B_REST_Utils.object_assert(lookupInfo);
			
			//FK for lookups point to PKs of non multi-field PK tables, and for now should always be INT, except if used as a "filter-like multiple standalone field" then it could be used as TYPE_ARR (ex for B_REST_Vuetify_GenericList_Filter in Vue implementation)
			if (!this.type_is_int && !this.type_is_arr) { this._throwField(`FK lookups can only be done on TYPE_INT fields (or TYPE_ARR, if used as a "filter-like multiple standalone field")`,lookupInfo); }
			
			this._lookup_modelName = lookupInfo.modelName || this._throwField(`When defining lookupInfo, must at least specify it's for which model name`);
			this._lookup_fieldName = lookupInfo.fieldName ?? null;
		}
		
		//Some late validations
		{
			//NOTE: We need JSON for when we want to use model fields to send an arr (as JSON) of enums to server
			if      (this.type_is_enum  && !this._enum_members)                     { this._throwField(`Must define enum_members on TYPE_ENUM`);                              }
			else if (this._enum_members && !(this.type_is_enum||this.type_is_json)) { this._throwField(`Can only define enum_members on TYPE_ENUM and optionally TYPE_JSON`); }
			
			if      ( this.type_is_decimal && !this._decimals) { this._decimals = 2;                                           }
			else if (!this.type_is_decimal &&  this._decimals) { this._throwField(`Can't define decimals on that field type`); }
			
			if ((this._min!==null||this._max!==null) && !B_REST_FieldDescriptor_DB._TYPES_WITH_BETWEEN_RANGE.includes(this._type) && this._type!==B_REST_FieldDescriptor_DB.TYPE_JSON) { this._throwField(`Can't define min/max on that field type`); }
		}
	}
	
	
	_abstract_updateLoc_fromNewerFieldDescriptor(fieldDescriptor_new)
	{
		if (this._enum_members)
		{
			for (const loop_enumMember_old of this._enum_members)
			{
				const loop_enumMemberTag = loop_enumMember_old.tag;
				
				const loop_enumMember_new = fieldDescriptor_new.enum_getMember_fromTag(loop_enumMemberTag);
				if (!loop_enumMember_new) { this._throwField(`Couldn't find enum member "${loop_enumMemberTag}" in new fieldDescriptor`,fieldDescriptor_new); }
				
				loop_enumMember_old.label = loop_enumMember_new.label;
			}
		}
	}
	
	
	get type()             { return this._type;               }
	get isPKField()        { return this._isPKField;          }
	get optionalVal()      { return this._optionalVal;        }
	get min()              { return this._min;                }
	get max()              { return this._max;                }
	get decimals()         { return this._decimals;           }
	get enum_members()     { return this._enum_members;       }
	get pwd_evalStrength() { return this._pwd_evalStrength;   }
	get lookup_is()        { return !!this._lookup_modelName; }
	get lookup_modelName() { return this._lookup_modelName;   }
	get lookup_fieldName() { return this._lookup_fieldName;   }
	
	//Must be an optional field, and if what we have in the field def is NULL, it only counts if it's nullable too
	get optionalVal_has()
	{
		if (this._isRequired)         { return false; }
		if (this._optionalVal!==null) { return true;  }
		return this._isNullable;
	}
	
	get type_is_string()             { return this._type===B_REST_FieldDescriptor_DB.TYPE_STRING;              }
	get type_is_int()                { return this._type===B_REST_FieldDescriptor_DB.TYPE_INT;                 }
	get type_is_decimal()            { return this._type===B_REST_FieldDescriptor_DB.TYPE_DECIMAL;             }
	get type_is_bool()               { return this._type===B_REST_FieldDescriptor_DB.TYPE_BOOL;                }
	get type_is_json()               { return this._type===B_REST_FieldDescriptor_DB.TYPE_JSON;                }
	get type_is_dt()                 { return this._type===B_REST_FieldDescriptor_DB.TYPE_DT;                  }
	get type_is_d()                  { return this._type===B_REST_FieldDescriptor_DB.TYPE_D;                   }
	get type_is_t()                  { return this._type===B_REST_FieldDescriptor_DB.TYPE_T;                   }
	get type_is_cStamp()             { return this._type===B_REST_FieldDescriptor_DB.TYPE_C_STAMP;             }
	get type_is_uStamp()             { return this._type===B_REST_FieldDescriptor_DB.TYPE_U_STAMP;             }
	get type_is_enum()               { return this._type===B_REST_FieldDescriptor_DB.TYPE_ENUM;                }
	get type_is_phone()              { return this._type===B_REST_FieldDescriptor_DB.TYPE_PHONE;               }
	get type_is_email()              { return this._type===B_REST_FieldDescriptor_DB.TYPE_EMAIL;               }
	get type_is_pwd()                { return this._type===B_REST_FieldDescriptor_DB.TYPE_PWD;                 }
	get type_is_calcFlatSearch()     { return this._type===B_REST_FieldDescriptor_DB.TYPE_CALC_FLAT_SEARCH;    }
	get type_is_arr()                { return this._type===B_REST_FieldDescriptor_DB.TYPE_ARR;                 }
	get type_is_multilingualString() { return this._type===B_REST_FieldDescriptor_DB.TYPE_MULTILINGUAL_STRING; }
	get type_is_custom()             { return this._type===B_REST_FieldDescriptor_DB.TYPE_CUSTOM;              }
	
	_abstract_factory_modelField(parentModel=null) { return new B_REST_ModelFields.DB(this,parentModel); }
	
	
	//Helper to prevent having to write huge lines of constants
		static create_type_string(name,options={})             { return new B_REST_FieldDescriptor_DB(name, B_REST_FieldDescriptor_DB.TYPE_STRING,             options); }
		static create_type_int(name,options={})                { return new B_REST_FieldDescriptor_DB(name, B_REST_FieldDescriptor_DB.TYPE_INT,                options); }
		static create_type_decimal(name,options={})            { return new B_REST_FieldDescriptor_DB(name, B_REST_FieldDescriptor_DB.TYPE_DECIMAL,            options); }
		static create_type_bool(name,options={})               { return new B_REST_FieldDescriptor_DB(name, B_REST_FieldDescriptor_DB.TYPE_BOOL,               options); }
		static create_type_json(name,options={})               { return new B_REST_FieldDescriptor_DB(name, B_REST_FieldDescriptor_DB.TYPE_JSON,               options); }
		static create_type_dt(name,options={})                 { return new B_REST_FieldDescriptor_DB(name, B_REST_FieldDescriptor_DB.TYPE_DT,                 options); }
		static create_type_d(name,options={})                  { return new B_REST_FieldDescriptor_DB(name, B_REST_FieldDescriptor_DB.TYPE_D,                  options); }
		static create_type_t(name,options={})                  { return new B_REST_FieldDescriptor_DB(name, B_REST_FieldDescriptor_DB.TYPE_T,                  options); }
		static create_type_c_stamp(name,options={})            { return new B_REST_FieldDescriptor_DB(name, B_REST_FieldDescriptor_DB.TYPE_C_STAMP,            options); }
		static create_type_u_stamp(name,options={})            { return new B_REST_FieldDescriptor_DB(name, B_REST_FieldDescriptor_DB.TYPE_U_STAMP,            options); }
		static create_type_enum(name,options={})               { return new B_REST_FieldDescriptor_DB(name, B_REST_FieldDescriptor_DB.TYPE_ENUM,               options); }
		static create_type_phone(name,options={})              { return new B_REST_FieldDescriptor_DB(name, B_REST_FieldDescriptor_DB.TYPE_PHONE,              options); }
		static create_type_email(name,options={})              { return new B_REST_FieldDescriptor_DB(name, B_REST_FieldDescriptor_DB.TYPE_EMAIL,              options); }
		static create_type_pwd(name,options={})                { return new B_REST_FieldDescriptor_DB(name, B_REST_FieldDescriptor_DB.TYPE_PWD,                options); }
		static create_type_calcFlatSearch(name,options={})     { return new B_REST_FieldDescriptor_DB(name, B_REST_FieldDescriptor_DB.TYPE_CALC_FLAT_SEARCH,   options); }
		static create_type_arr(name,options={})                { return new B_REST_FieldDescriptor_DB(name, B_REST_FieldDescriptor_DB.TYPE_ARR,                options); }
		static create_type_multilingualString(name,options={}) { return new B_REST_FieldDescriptor_DB(name, B_REST_FieldDescriptor_DB.TYPE_MULTILINGUAL_STRING,options); }
		static create_type_custom(name,options={})             { return new B_REST_FieldDescriptor_DB(name, B_REST_FieldDescriptor_DB.TYPE_CUSTOM,             options); }
	
	
	/*
	Takes a list of tags, and convert them to B_REST_FieldDescriptor_DB_EnumMember instances
	Assigns them locPath matching the parent model's name, for structured access
	Usage ex:
		enum_makeEnumMembersFromPipedTagList("contact", "gender", ["f","m"]);
		Yields:
			[
				new B_REST_FieldDescriptor_DB_EnumMember("f", extraData=null),
				new B_REST_FieldDescriptor_DB_EnumMember("m", extraData=null),
			]
	*/
	static enum_makeEnumMembersFromPipedTagList(modelName, fieldName, tags)
	{
		return tags.split("|").map(loop_tag => new B_REST_FieldDescriptor_DB_EnumMember(loop_tag,/*extraData*/null));
	}
	//May yield NULL
	enum_getMember_fromTag(tag)
	{
		if (!this._enum_members) { this._throwField(`This field doesn't have enum members`); }
		return this._enum_members.find(loop_enumMember => loop_enumMember.tag===tag);
	}
	
	
	//To help give info on validation
	get placeholder()
	{
		switch (this._type)
		{
			case B_REST_FieldDescriptor_DB.TYPE_STRING:              return this._placeholder_evalBetween("db.string");
			case B_REST_FieldDescriptor_DB.TYPE_INT:                 return this._placeholder_evalBetween("db.number");
			case B_REST_FieldDescriptor_DB.TYPE_DECIMAL:             return this._placeholder_evalBetween("db.number");
			case B_REST_FieldDescriptor_DB.TYPE_BOOL:                return null;
			case B_REST_FieldDescriptor_DB.TYPE_JSON:                return null;
			case B_REST_FieldDescriptor_DB.TYPE_DT:                  return null;
			case B_REST_FieldDescriptor_DB.TYPE_D:                   return null;
			case B_REST_FieldDescriptor_DB.TYPE_T:                   return null;
			case B_REST_FieldDescriptor_DB.TYPE_C_STAMP:             return null;
			case B_REST_FieldDescriptor_DB.TYPE_U_STAMP:             return null;
			case B_REST_FieldDescriptor_DB.TYPE_ENUM:                return null;
			case B_REST_FieldDescriptor_DB.TYPE_PHONE:               return "111-222-3333";
			case B_REST_FieldDescriptor_DB.TYPE_EMAIL:               return "example@domain.com";
			case B_REST_FieldDescriptor_DB.TYPE_ARR:                 return null;
			case B_REST_FieldDescriptor_DB.TYPE_MULTILINGUAL_STRING: return null;
			case B_REST_FieldDescriptor_DB.TYPE_PWD:                 return null;
			case B_REST_FieldDescriptor_DB.TYPE_CALC_FLAT_SEARCH:    return null;
			case B_REST_FieldDescriptor_DB.TYPE_CUSTOM:              return null;
			default: this._throwField(`Unknown type "${this._type}"`);
		}
	}
		_placeholder_evalBetween(prefix)
		{
			if (this._max!==null)
			{
				return this._min===null ? this.placeholder_translate(`${prefix}.max`,{max:this._max}) : this.placeholder_translate(`${prefix}.between`,{min:this._min,max:this._max});
			}
			
			return this._min!==null ? this.placeholder_translate(`${prefix}.min`,{min:this._min}) : null;
		}
	
	
	//Checks if the field descriptor defines something in particular for that, or if we fallback to core loc
	get loc_bool_null()  { return this._loc_bool_x(B_REST_App_base.LOC_KEY_BOOL_NULL);  }
	get loc_bool_true()  { return this._loc_bool_x(B_REST_App_base.LOC_KEY_BOOL_TRUE);  }
	get loc_bool_false() { return this._loc_bool_x(B_REST_App_base.LOC_KEY_BOOL_FALSE); }
		_loc_bool_x(which)
		{
			if (B_REST_Utils.object_hasPropName(this._loc,which)) { return this._loc[which]; }
			
			return B_REST_App_base.instance.t_core(`${B_REST_App_base.LOC_PATH_MODELS}.${B_REST_App_base.LOC_PATH_FIELDS}.boolTags.${which}`);
		}
	
	
	/*
	For cases where we got a string like "2022-01-18T13:35:57.083Z" or anything else, try to convert it
	Throws if field type isn't date/time, rets NULL if not set
	WARNING ABOUT TIMEZONES:
		We don't handle timezones, so we should avoid comparing dates with unix timestamp, as some instances could ret GMT-5 and some other GMT-0
	*/
	dtVal_toDate(val)
	{
		if (!val)                         { return null;                                 }
		if (B_REST_Utils.dt_is(val))      { return val;                                  }
		if (!B_REST_Utils.string_is(val)) { this._throwField(`Expected Date or string`); }
		
		switch (this._type)
		{
			//NOTE: We use utils for date casting instead of "new Date()" directly, because Safari doesn't support Ymd
			
			case B_REST_FieldDescriptor_DB.TYPE_DT: case B_REST_FieldDescriptor_DB.TYPE_C_STAMP: case B_REST_FieldDescriptor_DB.TYPE_U_STAMP:
				return B_REST_Utils.dt_fromYmdHis(val); //Throws if invalid
			case B_REST_FieldDescriptor_DB.TYPE_D:
				return B_REST_Utils.dt_fromYmdAndOrHis(val, /*stripDate*/false, /*stripTime*/true); //Throws if invalid
			case B_REST_FieldDescriptor_DB.TYPE_T:
				return B_REST_Utils.dt_fromYmdAndOrHis(val, /*stripDate*/true, /*stripTime*/false); //Throws if invalid
			default: this._throwField(`Can only use dtVal_toDate() on TYPE_D/T fields`);
		}
		
		return null;
	}
	/*
	We must ret them as Y-m-dTH:i(:s)
	NOTE: When we receive string dates, we don't validate if they make sense, to keep things quick
	WARNING: Might throw if we get a Date instance that's invalid like new Date("bob")
	*/
	dtVal_toHTML5InputVal(val)
	{
		if (!val) { return null; }
		
		const isDT = B_REST_Utils.dt_is(val);
		if (!isDT && !B_REST_Utils.string_is(val)) { this._throwField(`Expected Date or string`); }
		
		switch (this._type)
		{
			case B_REST_FieldDescriptor_DB.TYPE_DT: case B_REST_FieldDescriptor_DB.TYPE_C_STAMP: case B_REST_FieldDescriptor_DB.TYPE_U_STAMP:
				//NOTE: Algo in dtVal_toHTML5InputVal() & validation_type_normalizeVal() have to be opposite
				if (isDT) { return B_REST_Utils.dt_format(val,/*wDate*/true,/*wTime*/true,B_REST_FieldDescriptor_DB.DT_CARE_ABOUT_SECONDS,B_REST_FieldDescriptor_DB.DT_HTML5_SEPARATOR); }
				return val.replace(" ", B_REST_FieldDescriptor_DB.DT_HTML5_SEPARATOR); //Replace "Y-m-d H:i:s" by "Y-m-dTH:i:s"
			case B_REST_FieldDescriptor_DB.TYPE_D:
				if (isDT) { return B_REST_Utils.dt_toYmd(val); }
				return val;
			case B_REST_FieldDescriptor_DB.TYPE_T:
				if (isDT) { return B_REST_Utils.dt_format(val,/*wDate*/false,/*wTime*/true,B_REST_FieldDescriptor_DB.T_CARE_ABOUT_SECONDS); }
				return val;
			default: this._throwField(`Can only use dtVal_toHTML5InputVal() on TYPE_D/T fields`);
		}
		
		return null;
	}
	
	/*
	We do [raw pwd] > [frontend to backend encryption] > [db encryption]
	So API calls never show raw pwd
	Ex "pwd" -> "<intermediate>6a4b49f07b599056dc1dc08d2c68afd8c2dd49af1c346fb51c7d8d56576354a6e2608e8e161151cb92886e4fbde45ac9e4c1a69bbcf0566cce108abc0200e60a"
	For more info, check server's CryptoUtils
	Expects an algo like "sha512"
	NOTE:
		There's also a new native API, but the prob is that it's async: https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#basic_example
	*/
	pwdVal_toFrontendHash(raw)
	{
		if (!this.type_is_pwd) { this._throwField(`Can only do that on TYPE_PWD fields`); }
		
		return B_REST_App_base.instance.pwd_raw_toFrontendHash(raw);
	}
	
	/*
	Outputs the val for human readability contexts
	We can pass options like the following, to either put new vals to them or make them "":
		{
			bool_true:  "✔",
			bool_false: "✖",
			pwd_format: "******",
			custom_func(val) {}
		}
	*/
	valToText(val, options=null)
	{
		if (val===null) { return null; }
		
		switch (this._type)
		{
			case B_REST_FieldDescriptor_DB.TYPE_STRING:
				return val;
			case B_REST_FieldDescriptor_DB.TYPE_INT:
				return val;
			case B_REST_FieldDescriptor_DB.TYPE_DECIMAL:
				return B_REST_Utils.number_format(val,this._decimals);
			case B_REST_FieldDescriptor_DB.TYPE_BOOL:
				if (val===true)  { return options?.bool_true  ?? "✔"; }
				if (val===false) { return options?.bool_false ?? "✖"; }
				this._throwField(`Unsupported val for bool`,val);
				break;
			case B_REST_FieldDescriptor_DB.TYPE_JSON:
				B_REST_Utils.console_todo([`Find a better way to do than this / have multiple kinds of TYPE_JSON, etc`]);
				
				//If it's an obj (could be an arr), check if we could ret the 1st key we find, no matter what it is (could be as {fr,en}, or {whatever,else})
				if (B_REST_Utils.object_is(val))
				{
					const keys = Object.keys(val);
					if (keys.length>0) { return val[keys[0]]; }
				}
				
				this._throwField(`Not yet handling valToText() for json fields`);
				break;
			case B_REST_FieldDescriptor_DB.TYPE_DT: case B_REST_FieldDescriptor_DB.TYPE_D: case B_REST_FieldDescriptor_DB.TYPE_T: case B_REST_FieldDescriptor_DB.TYPE_C_STAMP: case B_REST_FieldDescriptor_DB.TYPE_U_STAMP:
				//In theory, they're already properly formatted and not Date instances
				return val;
			case B_REST_FieldDescriptor_DB.TYPE_ENUM:
				return this.enum_getMember_fromTag(val).label;
			case B_REST_FieldDescriptor_DB.TYPE_PHONE: case B_REST_FieldDescriptor_DB.TYPE_EMAIL:
				return val;
			case B_REST_FieldDescriptor_DB.TYPE_PWD:
				return options?.pwd_format ?? "******";
			case B_REST_FieldDescriptor_DB.TYPE_CALC_FLAT_SEARCH:
				return val;
			case B_REST_FieldDescriptor_DB.TYPE_ARR:
				B_REST_Utils.console_todo([`Should bind arr to some other map of id=>val to make textual sense`]);
				return val.join(", ");
			case B_REST_FieldDescriptor_DB.TYPE_MULTILINGUAL_STRING:
				//Show the current lang's loc, if filled. We can get something like just NULL, or ex {fr, en:null}
				const locale_lang = B_REST_App_base.instance.locale_lang;
				return val ? (val[locale_lang]??null) : null;
			case B_REST_FieldDescriptor_DB.TYPE_CUSTOM:
				if (!options?.custom_func) { this._throwField(`Must provide custom_func`); }
				return options.custom_func(val);
		}
	}
	
	//Only for TYPE_INT & TYPE_DECIMAL, converts string/numeric val to number, and if it's empty/NaN, converts it to a fallback val
	valToNumber(val, valIfNaN=0)
	{
		if (this._type!==B_REST_FieldDescriptor_DB.TYPE_INT && this._type!==B_REST_FieldDescriptor_DB.TYPE_DECIMAL) { this._throwField(`Only for numeric types`); }
		
		val = parseFloat(val);
		return isNaN(val) ? valIfNaN : val;
	}
	
	
	/*
	Normal html <input> or <textarea>, when we del their contents, put "" in the field, while clear btns usually put them to NULL instead.
	We have to make things constant, but it depends on the data type:
		TYPE_STRING              -> ""
		TYPE_INT                 -> NULL
		TYPE_DECIMAL             -> NULL
		TYPE_BOOL                -> NULL
		TYPE_JSON                -> NULL
		TYPE_DT                  -> NULL
		TYPE_D                   -> NULL
		TYPE_T                   -> NULL
		TYPE_C_STAMP             -> NULL
		TYPE_U_STAMP             -> NULL
		TYPE_ENUM                -> NULL
		TYPE_PHONE               -> NULL
		TYPE_EMAIL               -> NULL
		TYPE_PWD                 -> NULL
		TYPE_CALC_FLAT_SEARCH    -> NULL
		TYPE_ARR                 -> NULL
		TYPE_MULTILINGUAL_STRING -> "" (per lang key) (?)
		TYPE_CUSTOM              -> ??? Not sure what to do, but it has impacts in validation_type_errorMsg_eval() too
		-> Date, time, phone, email, pwd, are all entered as text in <input type="x">, but most of them make sense to become NULL when left blank,
			as opposed to <input type="text"> and <textarea>
	Also, for TYPE_INT & TYPE_DECIMAL, whenever possible, convert to numbers. We won't though if it's not castable, or when number string is too long.
		GLITCH:
			In Vue, if we have a <input v-model.lazy> and we put "123.45" in a TYPE_INT field, onblur will put back "123" in the field
			However, if we don't put the .lazy modifier, as we type it'll remain "123.45", even after we blur, but the .val will still have changed to "123"
		However, for some reason, if we're with ints and we type a float number, the actual val will change to an int, but in the field, we'll still see the old float val...
	For TYPE_BOOL, by default null turns to false, and then all bool-like vals are converted to bools
	*/
	validation_type_normalizeVal(val)
	{
		//Complex case for TYPE_MULTILINGUAL_STRING, where in frontend we must always make sure we define all lang keys even if we don't need them, otherwise we have prob w reactive frameworks
		if (this._type===B_REST_FieldDescriptor_DB.TYPE_MULTILINGUAL_STRING)
		{
			if (val===null)
			{
				val = {};
				for (const loop_lang of B_REST_App_base.instance.appLangs) { val[loop_lang]=null; }
			}
			else
			{
				if (!B_REST_Utils.object_is(val)) { this._throwField(`Can only receive objs like {fr,en,es}`); }
				
				for (const loop_lang of B_REST_App_base.instance.appLangs)
				{
					if (!B_REST_Utils.object_hasPropName(val,loop_lang) || val[loop_lang]==="") { val[loop_lang]=null; } //WARNING: Conversion of ""->NULL in B_REST_ModelField_DB::val_set_inXLang(), B_REST_ModelField_DB::_val_arrObjProxy_onAfterChanged() & B_REST_FieldDescriptor_DB::validation_type_normalizeVal()
				}
			}
			
			return val;
		}
		
		//Check note above for uniformizing NULL vs "" prior to validation
		if (this._type===B_REST_FieldDescriptor_DB.TYPE_STRING /* NOT SURE: || this._type===B_REST_FieldDescriptor_DB.TYPE_CUSTOM */)
		{
			if (val===null) { return ""; }
		}
		else if (val==="" /* NOT SURE: && !this._type===B_REST_FieldDescriptor_DB.TYPE_CUSTOM */) { return null; }
		
		if (val===null)
		{
			if (this._optionalVal!==null)
			{
				return B_REST_Utils.array_is(this._optionalVal) ? [...this._optionalVal] : this._optionalVal; //If it was an arr, make a copy of it, otherwise it'd be hell
			}
			
			return this._type===B_REST_FieldDescriptor_DB.TYPE_BOOL ? B_REST_FieldDescriptor_DB.TYPE_BOOL_NULL_NORMALIZED_VAL : null;
		}
		
		//NOTE: If we get there, val isn't NULL
		
		let parsed = null;
		switch (this._type)
		{
			case B_REST_FieldDescriptor_DB.TYPE_INT:
				if (isNaN(val)) { return val; }
				
				parsed = parseInt(val); //Do this to round down floats (doesn't actually get reflected in the input though; see method docs about the GLITCH) + for next check
				return -B_REST_FieldDescriptor_DB.INT_MAX_SIZE_BEFORE_EXPONENT_FLIP<=parsed && parsed<=B_REST_FieldDescriptor_DB.INT_MAX_SIZE_BEFORE_EXPONENT_FLIP ? parsed : val;
			case B_REST_FieldDescriptor_DB.TYPE_DECIMAL:
				if (isNaN(val)) { return val; }
				parsed = parseFloat(val);
				
				//Do this, otherwise we could get 118.89999999998 instead of 118.90, and it'd always say it's invalid in validation_type_errorMsg_eval()
				return B_REST_Utils.number_round(parsed,this._decimals);
					/*
					NOTE: Before, we used to do the following:
						if (parsed.toString().length>B_REST_FieldDescriptor_DB.DECIMAL_MAX_REPRESENTATION_LENGTH) { return val; }
					*/
			case B_REST_FieldDescriptor_DB.TYPE_BOOL:
				return val===true || val===1 || val==="1" ? true : false; //NOTE: NULL case handled above
			case B_REST_FieldDescriptor_DB.TYPE_DT: case B_REST_FieldDescriptor_DB.TYPE_C_STAMP: case B_REST_FieldDescriptor_DB.TYPE_U_STAMP:
				//NOTE: Algo in dtVal_toHTML5InputVal() & validation_type_normalizeVal() have to be opposite
				if (B_REST_Utils.dt_is(val)) { return val; }
				if (!B_REST_Utils.string_is(val)) { this._throwField(`Expected Date or string`); }
				return val.replace(B_REST_FieldDescriptor_DB.DT_HTML5_SEPARATOR, " "); //Replace "Y-m-dTH:i:s" by "Y-m-d H:i:s"
			case B_REST_FieldDescriptor_DB.TYPE_ARR:
				if (!B_REST_Utils.array_is(val)) { this._throwField(`Can only receive arrs`); }
				return val.length===0 ? null : val;
			default:
				return val;
		}
	}
	
	
	/*
	Rets a translated err msg or NULL if all is ok
	For more info, check server's FieldDescriptor_DB::_abstract_validity_checkNonNullVal()
	For TYPE_CUSTOM, validation will have to be done in B_REST_Descriptor::validation_custom_xFunc() or B_REST_Model::validation_custom_xFunc()
	WARNING FOR NULL VS "" and textual numbers vs actual numbers:
		Check notes in B_REST_FieldDescriptor_DB::validation_type_normalizeVal()
	*/
	validation_type_errorMsg_eval(val, modelField=null, forceIsRequired=false) //Optional instance of B_REST_ModelFields.DB
	{
		const isRequired = this._isRequired || forceIsRequired;
		const isNullable = this._isNullable && !isRequired;
		
		try
		{
			/*
			If it's either:
				-Not set
				-A false bool (thanks to validation_type_normalizeVal(), they can never be NULL)
				-An empty string when it's a string-based input that is not for email, phone, pwd...
			Check notes in B_REST_FieldDescriptor_DB::validation_type_normalizeVal()
			*/
			if (val===undefined || (val===false && this._type===B_REST_FieldDescriptor_DB.TYPE_BOOL) || (val==="" && (this._type===B_REST_FieldDescriptor_DB.TYPE_STRING /* NOT SURE: || this._type===B_REST_FieldDescriptor_DB.TYPE_CUSTOM */)))
			{
				return isRequired ? this.validation_translate("required",modelField) : null;
			}
			//If NULL. Check method's warnings about empty string
			else if (val===null)
			{
				if (isNullable) { return null; }
				return isRequired ? this.validation_translate("requiredNotNullable",modelField) : this.validation_translate("nullable",modelField);
			}
			//Else we can have any primitive val, even empty strings here, so we can validate per-type stuff
			else
			{
				switch (this._type)
				{
					case B_REST_FieldDescriptor_DB.TYPE_STRING:
						return this._validation_type_errorMsg_eval_stringLike(val, "db.string.like", modelField);
					
					case B_REST_FieldDescriptor_DB.TYPE_INT:
						return B_REST_Utils.int_is(val) ? this._validation_type_errorMsg_eval_between(val,"db.number", modelField) : this.validation_translate("db.number.int",modelField);
					
					case B_REST_FieldDescriptor_DB.TYPE_DECIMAL:
						if (!B_REST_Utils.number_is(val)) { return this.validation_translate("db.number.decimal.format",modelField); }
						const asFloat = parseFloat(val);
						if (B_REST_Utils.number_round(asFloat,this._decimals)!=asFloat) { return this.validation_translate("db.number.decimal.decimalsCount",modelField,{expectedCount:this._decimals}); } //NOTE: validation_type_normalizeVal() doing a number_round() should avoid this from happening in model field's _setVal()
						return this._validation_type_errorMsg_eval_between(val,"db.number", modelField);
					
					case B_REST_FieldDescriptor_DB.TYPE_BOOL:
						return val===true || val===false || val===1 || val===0 || val==="1" || val==="0" ? null : this.validation_translate("db.bool",modelField);
					
					case B_REST_FieldDescriptor_DB.TYPE_JSON:
						//NOTE: In backend, we also have to validate length, but we won't do that here, since we'd need to do B_REST_Utils.json_encode()
						return B_REST_Utils.array_is(val) || B_REST_Utils.object_is(val) ? null : this.validation_translate("db.json",modelField);
					
					case B_REST_FieldDescriptor_DB.TYPE_DT: case B_REST_FieldDescriptor_DB.TYPE_C_STAMP: case B_REST_FieldDescriptor_DB.TYPE_U_STAMP:
						return this._validation_type_errorMsg_eval_dt(val, "db.dt", modelField);
					
					case B_REST_FieldDescriptor_DB.TYPE_D:
						return this._validation_type_errorMsg_eval_dt(val, "db.d", modelField);
					
					case B_REST_FieldDescriptor_DB.TYPE_T:
						return this._validation_type_errorMsg_eval_dt(val, "db.t", modelField);
					
					case B_REST_FieldDescriptor_DB.TYPE_ENUM:
						if (!this._enum_members) { this._throwField(`Enum tags not yet defined`); } //Should never happen though
						return this.enum_getMember_fromTag(val) ? null : this.validation_translate("db.enum",modelField, {enumTags:this._enum_members.map(loop_enumMember=>loop_enumMember.tag).join(", ")});
					
					case B_REST_FieldDescriptor_DB.TYPE_PHONE:
						return this._validation_type_errorMsg_eval_stringLike(val, "db.phone", modelField);
						//NOTE: For now, we won't validate more, in case people add extensions and stuff
					
					case B_REST_FieldDescriptor_DB.TYPE_EMAIL:
						if (!B_REST_Utils.string_is(val) || !val.match(/^[^@]+@[^@.]+(\.[^@.]+)+$/)) { return this.validation_translate("db.email",modelField); } //Simplest regex for "a@b.c"
						return this._validation_type_errorMsg_eval_between(val.length, "db.string", modelField);
					
					case B_REST_FieldDescriptor_DB.TYPE_PWD:
						const stringLike = this._validation_type_errorMsg_eval_stringLike(val,"db.pwd",modelField);
						if (stringLike) { return stringLike; }
						return this._pwd_evalStrength ? this._validation_type_errorMsg_eval_pwdStrength(val,modelField) : null;
					
					case B_REST_FieldDescriptor_DB.TYPE_CALC_FLAT_SEARCH:
						return this._validation_type_errorMsg_eval_stringLike(val, "db.string.like", modelField);
					
					case B_REST_FieldDescriptor_DB.TYPE_ARR:
						//Treat empty arr as if it was NULL
						if (!B_REST_Utils.array_is(val)) { return this.validation_translate("db.arr",modelField); }
						if (isRequired && val.length===0) { this.validation_translate("required",modelField); }
						return null;
					
					case B_REST_FieldDescriptor_DB.TYPE_MULTILINGUAL_STRING:
						if (!B_REST_Utils.object_is(val)) { return this.validation_translate("db.multilingualString",modelField); }
						for (const loop_lang of B_REST_App_base.instance.appLangs)
						{
							const loop_val = val[loop_lang];
							if (isRequired && (loop_val===null||loop_val==="")) { return this.validation_translate("db.multilingualString",modelField); }
							else if (loop_val!==null)
							{
								const loop_lengthEtc = this._validation_type_errorMsg_eval_stringLike(loop_val, "db.string.like", modelField);
								if (loop_lengthEtc!==null) { return loop_lengthEtc; }
							}
						}
						return null;
					
					case B_REST_FieldDescriptor_DB.TYPE_CUSTOM:
						return null; //Check method's docs
				}
			}
		}
		catch (e)
		{
			B_REST_Utils.console_error(`An error occured while validating B_REST_FieldDescriptor_base<${this._name}>: ${e}`,modelField); //WARNING: Could cause prob to switch to throwEx() - check code below
			return this.validation_translate("db.unknownError",modelField);
		}
		
		this._throwField(`Unexpected type "${this._type}"`);
	}
		//If a Date instance, rets if it's a valid Date (since we can create bad instances...), or ex string like "2022-01-18T13:35:57.083Z". Never throw
		_validation_type_errorMsg_eval_dt(val, prefix, modelField)
		{
			if (B_REST_Utils.dt_is(val))
			{
				if (!B_REST_Utils.dt_isValid(val)) { return this.validation_translate(prefix,modelField); }
			}
			else if (!B_REST_Utils.dt_asString_isValid(val)) { return this.validation_translate(prefix,modelField); }
			
			if (this._min!==null || this._max!==null)
			{
				B_REST_Utils.console_todo([
					"Not yet supported min/max validation for D/T. Will have to convert to Date instances to compare, expect when it's a time maybe. Check backend B_REST_FieldDescriptor_DB::toDB() TODO throw",
				]);
			}
			
			return null;
		}
		//Common validation for things like number ranges and string length range
		_validation_type_errorMsg_eval_between(val, prefix, modelField=null)
		{
			if (this._max!==null && val>this._max)
			{
				return this._min===null ? this.validation_translate(`${prefix}.max`,modelField,{max:this._max,val}) : this.validation_translate(`${prefix}.between`,modelField,{min:this._min,max:this._max,val});
			}
			
			if (this._min!==null && val<this._min)
			{
				return this._max===null ? this.validation_translate(`${prefix}.min`,modelField,{min:this._min,val}) : this.validation_translate(`${prefix}.between`,modelField,{min:this._min,max:this._max,val});
			}
			
			return null;
		}
		//Common validation for TYPE_STRING, TYPE_PHONE, TYPE_PWD, & TYPE_CALC_FLAT_SEARCH. We might throw if it's not string like, and/or validate min/max length
		_validation_type_errorMsg_eval_stringLike(val, notStringLikeErrorTag=null, modelField=null)
		{
			if      (B_REST_Utils.string_is(val)) { return this._validation_type_errorMsg_eval_between(val.length,            "db.string", modelField); }
			else if (B_REST_Utils.number_is(val)) { return this._validation_type_errorMsg_eval_between(val.toString().length, "db.string", modelField); }
			//Else it's not string-like, so maybe we have to indicate it's invalid
			else if (notStringLikeErrorTag) { return this.validation_translate(notStringLikeErrorTag,modelField); }
			
			//If we get here, it means we only did care about validating max length IF it was string-like, or that all is well
			return null;
		}
		_validation_type_errorMsg_eval_pwdStrength(val, modelField=null)
		{
			if (B_REST_FieldDescriptor_DB.validation_getPwdStrengthLvl(val.toString()) < B_REST_FieldDescriptor_DB.PWD_MINIMUM_STRENGTH) { return this.validation_translate("db.pwd",modelField); }
			
			return null;
		}
	
	/*
	Rets 0 to 4. Should allow between 3-4
	https://github.com/dropbox/zxcvbn
	npm install zxcvbn
	*/
	static validation_getPwdStrengthLvl(pwd) { return zxcvbn(pwd??"").score; }
};






class B_REST_FieldDescriptor_ModelLookupRef extends B_REST_FieldDescriptor_WithFuncs_WithModels_base
{
	_fk_dbFieldName = null;
	_isShared       = null; //If we can make mods to this field directly "for this model", or if it's RO and used accross multiple models
		//WARNING: If we add loc fields here, fix _abstract_updateLoc_fromNewerFieldDescriptor()
	
	
	constructor(name, modelClassName, fk_dbFieldName, isShared=false, options={})
	{
		super(name, modelClassName, options);
		
		this._fk_dbFieldName = fk_dbFieldName;
		this._isShared       = isShared;
	}
	
	get fk_dbFieldName() { return this._fk_dbFieldName; }
	get isShared()       { return this._isShared;       }
	
	_abstract_factory_modelField(parentModel=null) { return new B_REST_ModelFields.ModelLookupRef(this,parentModel); }
	
	_abstract_updateLoc_fromNewerFieldDescriptor(fieldDescriptor_new) { }
};






class B_REST_FieldDescriptor_SubModel extends B_REST_FieldDescriptor_SubModel_base
{
	//WARNING: If we add loc fields here, fix _abstract_updateLoc_fromNewerFieldDescriptor()
	
	constructor(name, modelClassName, subModel_fkFieldName, options={})
	{
		super(name, modelClassName, subModel_fkFieldName, options);
	}
	
	
	_abstract_factory_modelField(parentModel=null) { return new B_REST_ModelFields.SubModel(this,parentModel); }
	
	_abstract_updateLoc_fromNewerFieldDescriptor(fieldDescriptor_new) { }
};






class B_REST_FieldDescriptor_SubModelList extends B_REST_FieldDescriptor_SubModel_base
{
	//WARNING: If we add loc fields here, fix _abstract_updateLoc_fromNewerFieldDescriptor()
	
	constructor(name, modelClassName, subModel_fkFieldName, options={})
	{
		super(name, modelClassName, subModel_fkFieldName, options);
	}
	
	
	_abstract_factory_modelField(parentModel=null) { return new B_REST_ModelFields.SubModelList(this,parentModel); }
	
	_abstract_updateLoc_fromNewerFieldDescriptor(fieldDescriptor_new) { }
};






class B_REST_FieldDescriptor_Other extends B_REST_FieldDescriptor_WithFuncs_base
{
	//WARNING: If we add loc fields here, fix _abstract_updateLoc_fromNewerFieldDescriptor()
	
	constructor(name, options={})
	{
		super(name, options);
	}
	
	
	_abstract_factory_modelField(parentModel=null) { return new B_REST_ModelFields.Other(this,parentModel); }
	
	_abstract_updateLoc_fromNewerFieldDescriptor(fieldDescriptor_new) { }
};






class B_REST_FieldDescriptor_File extends B_REST_FieldDescriptor_base
{
	static get MAX_FILE_COUNT_NO_LIMIT() { return false; }
	static get FILES_MIME_PATTERNS_DANGEROUS() { return B_REST_Utils.FILES_MIME_PATTERNS_DANGEROUS; }
	static get FILES_MIME_PATTERNS_IMG()       { return B_REST_Utils.FILES_MIME_PATTERNS_IMG;       }
	static get FILES_MIME_PATTERNS_PDF()       { return B_REST_Utils.FILES_MIME_PATTERNS_PDF;       }
	static get FILES_MIME_PATTERNS_WORD()      { return B_REST_Utils.FILES_MIME_PATTERNS_WORD;      }
	static get FILES_MIME_PATTERNS_PDF_WORD()  { return B_REST_Utils.FILES_MIME_PATTERNS_PDF_WORD;  }
	static get FILES_MIME_PATTERNS_EXCEL()     { return B_REST_Utils.FILES_MIME_PATTERNS_EXCEL;     }
	static get FILES_MIME_PATTERNS_ANY()       { return B_REST_Utils.FILES_MIME_PATTERNS_ANY;       }
	
	static get FILE_SIZE_KB() { return B_REST_Utils.FILE_SIZE_KB; }
	static get FILE_SIZE_MB() { return B_REST_Utils.FILE_SIZE_MB; }
	
	_isMultiple        = null;
	_maxFileCount      = null; //MAX_FILE_COUNT_NO_LIMIT or nb
	_softDelete        = false;
	_maxFileSize       = null;
	_allowedTypes      = null; //One of FILES_MIME_PATTERNS_x or mime/etx string
	_image_minW        = null;
	_image_maxW        = null;
	_image_minH        = null;
	_image_maxH        = null;
	_image_resize_func = null;
		//WARNING: If we add loc fields here, fix _abstract_updateLoc_fromNewerFieldDescriptor()
	
	
	constructor(name, isMultiple, options={})
	{
		super(name, options);
		
		this._isMultiple = isMultiple;
		
		if (B_REST_Utils.object_hasPropName(options,"maxFileCount"))      { this._maxFileCount      = options.maxFileCount;      }
		if (B_REST_Utils.object_hasPropName(options,"softDelete"))        { this._softDelete        = options.softDelete;        }
		if (B_REST_Utils.object_hasPropName(options,"maxFileSize"))       { this._maxFileSize       = options.maxFileSize;       }
		if (B_REST_Utils.object_hasPropName(options,"allowedTypes"))      { this._allowedTypes      = options.allowedTypes;      }
		if (B_REST_Utils.object_hasPropName(options,"image_minW"))        { this._image_minW        = options.image_minW;        }
		if (B_REST_Utils.object_hasPropName(options,"image_maxW"))        { this._image_maxW        = options.image_maxW;        }
		if (B_REST_Utils.object_hasPropName(options,"image_minH"))        { this._image_minH        = options.image_minH;        }
		if (B_REST_Utils.object_hasPropName(options,"image_maxH"))        { this._image_maxH        = options.image_maxH;        }
		if (B_REST_Utils.object_hasPropName(options,"image_resize_func")) { this._image_resize_func = options.image_resize_func; }
	}
	
		
	get isMultiple()        { return this._isMultiple;        }
	get maxFileCount()      { return this._maxFileCount;      }
	get softDelete()        { return this._softDelete;        }
	get maxFileSize()       { return this._maxFileSize;       }
	get allowedTypes()      { return this._allowedTypes;      }
	get image_minW()        { return this._image_minW;        }
	get image_maxW()        { return this._image_maxW;        }
	get image_minH()        { return this._image_minH;        }
	get image_maxH()        { return this._image_maxH;        }
	get image_resize_func() { return this._image_resize_func; }
	
	
	_abstract_factory_modelField(parentModel=null) { return new B_REST_ModelFields.File(this,parentModel); }
	
	_abstract_updateLoc_fromNewerFieldDescriptor(fieldDescriptor_new) { }
};






export default {
	base:                      B_REST_FieldDescriptor_base,
	WithFuncs_base:            B_REST_FieldDescriptor_WithFuncs_base,
	WithFuncs_WithModels_base: B_REST_FieldDescriptor_WithFuncs_WithModels_base,
	SubModel_base:             B_REST_FieldDescriptor_SubModel_base,
	DB_EnumMember:             B_REST_FieldDescriptor_DB_EnumMember,
	DB:                        B_REST_FieldDescriptor_DB,
	ModelLookupRef:            B_REST_FieldDescriptor_ModelLookupRef,
	SubModel:                  B_REST_FieldDescriptor_SubModel,
	SubModelList:              B_REST_FieldDescriptor_SubModelList,
	Other:                     B_REST_FieldDescriptor_Other,
	File:                      B_REST_FieldDescriptor_File,
};

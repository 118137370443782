<template>
	
	<!--
		NOTE:
			We can't change declaration order to:
				<v-card>
					<v-fade-transition>
						<template v-if>
						<template v-else-if>
						<template v-else>
			Because the last case has multiple child nodes and Vue <transition>
				would require using <transition-group> and lots of CSS to fix it
	-->
	<v-fade-transition hide-on-leave>
		
		<v-card v-if="!derivedComponent.fromX_anyFilled" v-bind="card_attrs">
			<v-card-text>
				<v-alert type="error">{{ derivedComponent.t_alt("noSourceModel") }}</v-alert>
			</v-card-text>
		</v-card>
		
  		<v-card v-else-if="derivedComponent.model_isLoading" v-bind="card_attrs" v-show="derivedComponent.showSkeletonLoader">
			<v-card-text>
				<v-skeleton-loader height="940" type="card, article@2, actions" />
			</v-card-text>
		</v-card>
		
		<v-card v-else v-bind="card_attrs" :loading="derivedComponent.model_isSaving">
			<v-card-title v-if="derivedComponent.mixinOptions.hasTitle && derivedComponent.showTitle" class="justify-space-between">
				<slot name="title">{{ derivedComponent.title }}</slot>
				<v-btn icon v-if="derivedComponent.closable" @click="derivedComponent.close()"><v-icon>mdi-close</v-icon></v-btn>
			</v-card-title>
			<v-card-text>
				<v-alert v-if="todos_showBanner" type="info" border="left" :icon="null" prominent class="todos-container mb-8">
					<div class="text-h6">TODOs</div>
					<ul class="text-body-2">
						<li v-for="(loop_todoInfo,loop_idx) in derivedComponent.mixinOptions.todos" :key="loop_idx" :class="derivedComponent.todos_getClasses(loop_todoInfo)">{{ loop_todoInfo.text }}</li>
					</ul>
				</v-alert>
				<slot name="fields" />
			</v-card-text>
			<v-card-actions v-if="isSlotDefined_actions||derivedComponent.apiBaseUrl_has" class="justify-end">
				<slot name="actions">
					<v-btn @click.stop.prevent="derivedComponent.awaitUnsavedChangesSaved_alwaysResolve()" :disabled="!derivedComponent.shouldSavingBeEnabled">
						{{ derivedComponent.t_alt("save.label") }}
					</v-btn>
				</slot>
			</v-card-actions>
			<v-card-text v-if="derivedComponent.final_showValidationErrs" class="text-body-2 error--text">
				<div v-html="derivedComponent.model_validationErrors.join('<br />')" />
			</v-card-text>
		</v-card>
		
	</v-fade-transition>
	
</template>

<script>
	
	/*
	Usage ex (MyForm.vue):
		*** Check the IMPORTANT msg
		Template:
			<br-generic-form-base :derived-component="_self">
				<template #fields>
					<v-row>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="firstName" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="lastName" />  </v-col>
					</v-row>
				</template>
			</br-generic-form-base>
		Script:
			import { B_REST_Vuetify_GenericFormBase_createMixin } from "@/bREST/core/implementations/vue/vuetifyComponents/genericModules/form/BrGenericFormBase.vue";
			export default {
				name: "citizenForm",
				mixins: B_REST_Vuetify_GenericFormBase_createMixin({
					modelName: "TestSPADCitizen",
					apiBaseUrl: "/testSPADCitizens/",
					async modelReady() { },
					async beforeLoad(request) { },
					async afterLoad(response,models) { },
					async beforeSave(request,model) { },
					async afterSave(response,model,isSuccess,wasNew) { },
					async customValidator() { },
				}),
				data()
				{
					return { };
				},
			}
		Usage:
			<my-form :from-route-info="routeInfo" />  // Check B_REST_VueApp_base::_routes_define_genericListFormModule() & B_REST_VueApp_RouteDef::convertToVueRouteDefObj() docs; Vue Router route obj needs a props() func
			<my-form :from-model="model" />
			<my-form from-pk-tag="*" />
			<my-form from-pk-tag="123" />
			<my-form from-new />
	IMPORTANT:
		Check B_REST_VueApp_base::_routes_define_genericListFormModule() for the transferComponentProps_forRouteInfo() prop,
			that passes down the req isCurrentRouteMainComponent when we load the component directly
	About idea of nesting <router-view>:
		Ex for a case like in SPAD w a citizen form ("/citizens/123") where we can view his animals ("/citizens/123/animals/456")
		Would prevent losing data in main form, but don't, because:
			-Switching new/existing pks reuse components when -sometimes- we don't want that
				(going from new->created pk is ok, but existing pk to new one, or pk1 to pk2 should clear)
			-We'd need to put :key on the <router-view> instances, to control when component should refresh,
				but we have no easy way of indicating to which instance do a key++ to force refresh
			-Not ok that sub form knows that it's being used in a parent form
			-We can't do $bREST.routes_go_back() to "close" the sub form (going from /citizen/123/animals/justCreatedOne to /citizen/123)
				so closing the sub form would reload main form anyways
		-> Best is:
			-Define beforeNavigation() hook in problematic components
			-Just open sub form in a modal w/o screwing main form
	*/
	
	import { B_REST_Utils, B_REST_Model, B_REST_Model_CustomValidationErrorList } from "../../../../../classes";
	import B_REST_VueApp_base                                                     from "../../../B_REST_VueApp_base.js";
	import B_REST_VueApp_CreateCoreMixin                                          from "../../../B_REST_VueApp_CreateCoreMixin.js";
	
	const CORE_ALT_BASE_LOC_PATH = "app.components.BrGenericFormBase";
	
	
	
	B_REST_Utils.console_todo([
		`What about if it's a sub form where we need an FK from the parent ? Then the parent should have a ModelList and set field correctly`,
		`Simplify by merging back w BaseTest.vue`,
		`Some custom validation that might be distinct to a place`,
		`If we have pwd fields and other custom fields, where to define them`,
		`Sometimes maybe we want to pass common lists we don't want to recalc`,
		`Allow models to return their form's + api resource URLs ? For UI, maybe we shouldn't and it should be the model list itself that controls that, since we could have multiple views for the same thing / change w lang`,
	]);
	
	
	
	export default {
		props: {
			derivedComponent: {type:Object, required:true}, //Derived component instance using this base component
		},
		computed: {
			//Since we have 3 <v-card>, do this so styling is consistent in all 3 cases
			card_attrs()
			{
				const defaultAttrs = {elevation:4, class:"pa-4"}; //IMPORTANT: Don't change back to "ma-4 pa-4", otherwise when we use a form in a <v-dialog>, will produce scrollbars floating around
				const attrs        = this.derivedComponent.cardAttrs ? {...defaultAttrs,...this.derivedComponent.cardAttrs} : defaultAttrs;
				
				if (attrs.class)
				{
					if      (B_REST_Utils.string_is(attrs.class)) { attrs.class+=` ${this.derivedComponent.cssClassBase}`; }
					else if (B_REST_Utils.array_is( attrs.class)) { attrs.class.push(this.derivedComponent.cssClassBase);  }
					else if (B_REST_Utils.object_is(attrs.class)) { attrs.class[this.derivedComponent.cssClassBase]=true;  }
					else                                          { attrs.class=this.derivedComponent.cssClassBase;        }
				}
				else { attrs.class=this.derivedComponent.cssClassBase; }
				
				return attrs;
			},
			isSlotDefined_title()   { return !!this.$slots.title;   },
			isSlotDefined_fields()  { return !!this.$slots.fields;  },
			isSlotDefined_actions() { return !!this.$slots.actions; },
			todos_showBanner()      { return this.$bREST.genericForm_showTodos && this.derivedComponent.mixinOptions.todos.length>0; },
		},
	};
	
	
	
	export function B_REST_Vuetify_GenericFormBase_createMixin(mixinOptions)
	{
		mixinOptions = B_REST_Utils.object_hasValidStruct_assert(mixinOptions, {
			modelName:          {accept:[String],  required:true}, //Ex: "Citizen"
			apiBaseUrl:         {accept:[String],  required:true}, //Ex: "/citizens/". Will automatically yield stuff like "/citizens/{pkTag}" for load & patch
			needsAccessToken:   {accept:[Boolean], default:true},  //Check notes in B_REST_Request_base::_needsAccessToken var def. Either bool or B_REST_Request_base::NEEDS_ACCESS_TOKEN_DONT (ex for login calls)
			hasTitle:           {accept:[Boolean], default:true},  //Takes precedence over usage's showTitle
			showValidationErrs: {accept:[Boolean], default:true},  //If we want to display a region after the actions row, so ex when save btn is disabled we know why
			showSkeletonLoader: {accept:[Boolean], default:true},  //Especially for when using component as a route and loading an existing record, if we want to display a loader, or just a blank page until it's ready. (Actually, what we really want to do is ask if we want the model to be loaded BEFORE the <router-view> goes here, but we can't use beforeRouteEnter() because it has no this). Otherwise, should be false if things always load quickly
			requiredFields:     {accept:[String]},                 //Ex: "firstName"
			todos:              {accept:[Array]},                  //Arr of {isDone,isBug,text} that will appear automatically at the top of the form
			modelReady:         {accept:[Function]},               //Async hook as (), happening when "this.model" is available, either right in created() when isNew, or after afterLoad() is completed (each time model changes)
			beforeLoad:         {accept:[Function]},               //Async hook as (request<B_REST_Request>) - Check B_REST_Descriptor::load_x() docs
			afterLoad:          {accept:[Function]},               //Async hook as (response<B_REST_Response>, models<B_REST_Model arr>) - Check B_REST_Descriptor::load_x() docs
			beforeSave:         {accept:[Function]},               //Async hook as (request<B_REST_Request>,   model<B_REST_Model>) - Check B_REST_Model::awaitUnsavedChangesSaved() docs
			afterSave:          {accept:[Function]},               //Async hook as (response<B_REST_Response>, model<B_REST_Model>, isSuccess, wasNew) - Check B_REST_Model::awaitUnsavedChangesSaved() docs
			customValidator:    {accept:[Function]},               //Async hook as () - For updating custom validation err msgs right before saving. No need to ret anything
			autoUpdateInterval: {accept:[Number]},                 //If when !isNew, we want to auto save changes when form is valid. WARNING: If model implements B_REST_Descriptor::validation_custom_asyncFuncs, we shouldn't do so
		}, "Form base");
		if (mixinOptions.formName) { B_REST_Utils.throwEx(`Tmp err msg: remove formName from mixin options and define component's name`); }
		
		return [
			//This creates funcs like t(), and requires that component defines its name Vue prop. WARNING: Must define component's name too
			B_REST_VueApp_CreateCoreMixin({
				coreAltBaseLocPath: CORE_ALT_BASE_LOC_PATH,
			}),
			//Our mixin
			{
				isGenericFormBase: true, //Ex if we want to test whether a component import is a form, prior to actually using it. Check w B_REST_Vuetify_GenericFormBase_isDerivedComponentImport()
				mixinOptions, //IMPORTANT: Leave this here; for BrGenericListBase, helps in BrGenericFormBaseSubModelList
				props: {
					showTitle:                             {type:Boolean,      required:false, default:true},  //Requires that module's hasTitle=true. Check B_REST_VueApp_base::_routes_define_genericListFormModule() & B_REST_VueApp_RouteDef::convertToVueRouteDefObj() docs; Vue Router route obj needs a props() func
					extraData:                             {type:Object,       required:false, default:null},  //An extra {} we can pass, ex if we wanted to have something like QSA but we don't have a B_REST_VueApp_RouteInfo. We could pass something like {some_fk} so that field is set in advance w some val from outside
					cardAttrs:                             {type:Object,       required:false, default:null},  //Will be passed in main <v-card>'s v-bind. Can override what's being set by default, like elevation and classes. Check card_attrs() code above
					closable:                              {type:Boolean,      required:false, default:false}, //Especially for when used via BrGenericListBase::openFormInVDialog(). Will $emit("close")
					//IMPORTANT: Check the usage ex in the docs above for how this works. One of these must be set
					fromModel:                             {type:B_REST_Model, required:false, default:null},
					fromPkTag:                             {type:undefined,    required:false, default:null},  //Something like "*" (NEW), 123 or fr-123. Either we set it directly
					isCurrentRouteMainComponent:           {type:Boolean,      required:false, default:false}, //Yields the same result as fromPkTag, except we get it from B_REST_App_base::routes_current_pathVars_pkTag. Check B_REST_VueApp_base::_routes_define_genericListFormModule()
					isCurrentRouteMainComponent_watchHack: {type:Number,       required:false, default:null},  //Ex we're in "/clients/123" and want to go to "/clients/456" wo reloading page; _fromX_setLoadModel() wouldn't be called because isCurrentRouteMainComponent doesn't change val, so need a watch on a ++ field to force "reloading" the form. Shouldn't rely on VueRouter URL; check B_REST_VueApp_base::_routes_define_genericListFormModule() docs for why
					fromNew:                               {type:Boolean,      required:false, default:null},  //Another way to express we want to create a new instance w/o having to pass a new one ourselves
					fromHooks:                             {type:Boolean,      required:false, default:null},  //When we use a combination of mixinOptions:{beforeLoad,beforeSave} to set the API path, pathVars etc ourselves, along w a mix of the "extraData" prop (ex for an account creation / myProfile path, reusing an existing <client-form>. Not only limited to using w existing records
					//Stuff for when it's used as a sub form of some main entity, when used in a picker, BrGenericListBase::openFormInVDialog(), etc. Ex when route is /citizens/:parent_pkTag/animals/:pkTag. Helps setting default fields in modelReady() when model.isNew. For picker, check BrFieldDb::_picker_emit() docs for normal use cases, including having a [+] btn to create on the fly, specifying opt parent FK. WARNING: If we want to change algo, consider complex CPA case where we have 3 models at the same time, ex we're in a EventForm, we want to choose a FranchiseePark, so open FranchiseeParkList picker, click [+], and transfer a franchisee_fk that has nothing to do w the parent EventForm
					parent_pkTag:        null, //When in a parent/child modules context, this will always be set; can't get there if parent model is still isNew
					parent_modelName:    null, //Ex if we do BrGenericListBase::openFormInVDialog(), we'll always feed this. Ex "Citizen"
					parent_routeName:    null, //Ex if we are isCurrentRouteMainComponent for a _routes_define_genericListFormSubModule("citizen>animal") route, will yield "citizen". WARNING: Avoid using, as will be NULL when !isCurrentRouteMainComponent
					parent_saveCallback: null, //For when we're from BrGenericListBase::openFormInVDialog(), to call each time we save
				},
				data()
				{
					const componentName = this.$options.name; //NOTE: Here, "this.componentName" isn't yet available as it's defined from within the mixin
					
					return {
						mixinOptions,
						model:                  null, //Final B_REST_Model instance, either from a received instance, existing PK or "*" for new
						autoUpdateInterval_ptr: null, //Ptr on a setInterval() handle
						savePromise:            null, //Promise that's only set while we're saving, to prevent saving multiple times in parallel
						customErrorList:        new B_REST_Model_CustomValidationErrorList(`components.${componentName}.customValidationErrors`),
					};
				},
				watch: {
					//IMPORTANT: Don't change any of these to {handler,deep:true}. Check B_REST_VueApp_base::_routes_define_genericListFormModule() docs for why
					fromModel()                             { this._fromX_setLoadModel(); },
					fromPkTag()                             { this._fromX_setLoadModel(); },
					isCurrentRouteMainComponent()           { this._fromX_setLoadModel(); },
					isCurrentRouteMainComponent_watchHack() { this._fromX_setLoadModel(); },
					fromNew()                               { this._fromX_setLoadModel(); },
					fromHooks()                             { this._fromX_setLoadModel(); },
					/*
					WARNING:
						Don't put $refs on a form to get things like model_isLoading/model_isSaving, as $refs aren't reactive. Use the below events
						However, careful in the scenario where a user creation form is only displayed while !B_REST_App_base::user_isAuth,
							as user info will be populated before model_isSaving() changes, so even in beforeDestroy(), component would never know to fire @model:isSaving=false
						Usage case:
							Some wrapper component using a form within its component:
								<client-form @model:isSaving="form_isSaving=$event" />
								data: {form_isSaving:false},
								watch: {
									form_isSaving() { this.$emit("form_isSaving",this.form_isSaving); },
								},
								beforeDestroy() { this.$emit("form_isSaving",false); },
							Some component using that wrapper, but kicking it out once logged in:
								<v-card :loading="innerFormSaving" :disabled="innerFormSaving">
									<client-form-wrapper v-if="!$bREST.user_isAuth" @form_isSaving="innerFormSaving=$event" />
								data: {innerFormSaving:false},
					*/
						model_isLoading() { this.$emit("model:isLoading",this.model_isLoading); },
						model_isSaving()  { this.$emit("model:isSaving", this.model_isSaving);  },
				},
				created() { this._fromX_setLoadModel(); },
				computed: {
					uid()                     { return this._uid; }, //All Vue component instances have a unique id
					self()                    { return this; }, //NOTE: Alternative is $vnode.componentInstance (===this), but $vnode keeps on pointing to new objs each time DOM changes, so unless we put a data() on the sub prop, it'd be an overreactive computed
					isGenericFormBase()       { return true; }, //Helper required for BrGenericFormBaseSubModelList.vue
					quotedName()              { return `B_REST_Vuetify_GenericForm<${this.componentName}>`; },
					cssClassBase()            { return `generic-form--${this.componentName}`;               },
					showSkeletonLoader()      { return this.mixinOptions.showSkeletonLoader;                },
					apiBaseUrl_has()          { return !!this.mixinOptions.apiBaseUrl;                      },
					apiBaseUrl_post()         { return this.apiBaseUrl_has ?    this.mixinOptions.apiBaseUrl                                         : null; },
					apiBaseUrl_pk()           { return this.apiBaseUrl_has ? `${this.mixinOptions.apiBaseUrl}{${B_REST_Model.API_PATH_VARS_PK_TAG}}` : null; }, //Ex "/clients/{pkTag}". IMPORTANT: Don't rename; used in BrGenericFormBaseSubModelList
					fromX_anyFilled()         { return !!this.isCurrentRouteMainComponent || (this.fromPkTag!==null && this.fromPkTag!=="") || !!this.fromModel || this.fromNew || this.fromHooks; }, //Sorted by probability order
					model_isLoading()         { return !this.model && (this.fromPkTag||this.isCurrentRouteMainComponent||this.fromHooks); }, //For loader cases where model isn't loaded/newed() yet. WARNING: Don't put a $refs on a form to get this computed, as $refs aren't reactive. Check watchs definition, to be able to get things like when we're saving/loading
					model_has()               { return !!this.model;                            },
					model_hasUnsavedChanges() { return this.model?.unsavedChanges_has ?? false; },
					//WARNING: Don't put a $refs on a form to get this computed, as $refs aren't reactive. Check watchs definition, to be able to get things like when we're saving/loading
					model_isSaving()
					{
						if (!this.model) { return false; }
						return this.savePromise!==null||this.model.isSaving||this.model.isSaving_hostModelField||false;
					},
					model_pkTag()          { return this.model?.pk_tag                ?? null;  },
					model_toLabel()        { return this.model?.toLabel("form")       ?? null;  },
					descriptor_isAutoInc() { return this.model?.descriptor?.isAutoInc ?? false; },
					//NOTE: To help debugging, consider using showValidationErrs=true
					shouldSavingBeEnabled()
					{
						if (!this.model || !this.descriptor_isAutoInc)                                       { return false; } //Can only save AUTO_INC models for now
						if (!this.model_isNew && !this.model_hasUnsavedChanges)                              { return false; } //Can always save when isNew, otherwise as long as we have unsaved changes
						if (this.model_isSaving)                                                             { return false; } //We shouldn't, if we're already saving
						if (!this.model.saving_can_fastOnly || this.customErrorList.fast_getMsgs().length>0) { return false; } //If model or -this form- has "fast" custom errs. NOTE: We shouldn't care about "async" errors, otherwise if ever they become in an err state we won't be able to try to save again even after "solving the prob"
						
						return true;
					},
					final_showValidationErrs() { return this.mixinOptions.showValidationErrs && this.model_validationErrors.length>0; },
					model_validationErrors()
					{
						if (!this.model) { return []; }
						
						return [
							...this.model.validation_getErrors(/*detailed*/false,/*onlyOne*/false,/*includeAsyncCustomErrors*/true),
							...this.customErrorList.fast_getMsgs(),
							...this.customErrorList.async_getMsgs(),
						];
					},
					model_isNew()
					{
						if (!this.model) { return true; }
						
						if (!this.descriptor_isAutoInc)
						{
							this.$bREST.utils.console_warn(`For now, can't tell if a non AUTO_INC record is new / existing. Check how it's done in server`); //WARNING: Impacts in shouldSavingBeEnabled() & save() too
							return true;
						}
						
						return this.model.isNew;
					},
					/*
					Loc ex:
						{
							...
							"title": {"new":"New citizen", "existing":"Citizen #{pkTag} ({autoLabel})"}
							...
						}
					*/
					title()
					{
						return this.model_isNew ? this.t_alt("title.new") : this.t_alt("title.existing",{pkTag:this.model_pkTag,autoLabel:this.model_toLabel});
					},
				},
				methods: {
					throwEx(msg,details=null) { this.$bREST.throwEx(`${this.quotedName}: ${msg}`, details); },
					async _fromX_setLoadModel()
					{
						if (this.$bREST.routerHack_isIgnoringReplaceEvents) { return; } //Happens when we create and change from ex "/clients/*" to "/clients/123", because B_REST_VueApp_base::_routes_define_genericListFormModule()'s transferComponentProps_forRouteInfo() gets called (and it has to)
						
						this.coreMixin_clearTimeoutsAndIntervals(); //Do this, to prevent hell w ongoing things for prev record to happen on the new one
						
						/*
						WARNING:
							Not sure about this; when we switch between ents, we should prolly "cut" the link w the previous ent before waiting for a next one to load and crash halfway through
							However, could cause _awaitUnsavedChangesSaved() below to fuck suddenly a this.model=NULL while trying to do this.model.userTouch_toggleAllFields() directly after
							a call to save, if we're in a UserForm.vue and trying to change the lang of our own user.
							Did a tweak in B_REST_VueApp_base::_abstract_onLangChange() to reduce hell. Check its warning docs for more info
						*/
						this.model = null;
						
						this._checkAutoUpdateInterval_needs(/*forceRemoval*/true);
						
						let model = null;
						if (this.fromX_anyFilled)
						{
							if (this.fromModel)
							{
								const fromModelName = this.fromModel.descriptor.name;
								if (fromModelName!==this.mixinOptions.modelName) { this.throwEx(`Expected a B_REST_Model instance of ${this.mixinOptions.modelName}, got ${fromModelName}`); }
								
								model = this.fromModel;
							}
							else if (this.fromNew) { model=B_REST_Model.commonDefs_make(this.mixinOptions.modelName); }
							else
							{
								let fromPkTag = null;
								
								if (this.fromPkTag) { fromPkTag=this.fromPkTag; }
								else if (this.isCurrentRouteMainComponent)
								{
									const routeDef          = this.$bREST.routes_current_def;
									const pathVarName_pkTag = routeDef.pathVarNames_sub_pkTag || routeDef.pathVarNames_pkTag;
									
									fromPkTag = this.$bREST.routes_current_pathVars[pathVarName_pkTag];
									if (!fromPkTag) { this.throwEx(`Couldn't figure out which pkTag to use`); }
								}
								else if (this.fromHooks) { /* Do nothing for now; leave fromPkTag=null and just enter the following else block */ }
								
								if (fromPkTag==="*") { model=B_REST_Model.commonDefs_make(this.mixinOptions.modelName); }
								else
								{
									const options = {
										requiredFields:              this.mixinOptions.requiredFields,
										apiBaseUrl:                  fromPkTag!==null ? this.apiBaseUrl_pk : this.apiBaseUrl_post, //Ex "/clients/{pkTag}"
										apiBaseUrl_path_vars:        {}, //Will add pkTag below, if we must
										apiBaseUrl_needsAccessToken: this.mixinOptions.needsAccessToken,
										beforeLoad:                  this.mixinOptions.beforeLoad ? async(request)        =>this.mixinOptions.beforeLoad.call(this,request)         : null, //Must do that if we want to have "this" working in the callbacks
										afterLoad:                   this.mixinOptions.afterLoad  ? async(response,models)=>this.mixinOptions.afterLoad.call( this,response,models) : null, //Must do that if we want to have "this" working in the callbacks
									};
									
									if (fromPkTag!==null) { options.apiBaseUrl_path_vars[B_REST_Model.API_PATH_VARS_PK_TAG]=fromPkTag; }
									
									/*
									NOTE: In case usage wants to change API base URL, when just mixinOptions.apiBaseUrl etc isn't enough:
										Ex we want to reuse a <client-form> in a MyProfile.vue, and we want all "/clients/<*|pkTag>" calls to become "/myProfile" w no pkTag
										Use beforeLoad() mixin instead, ex:
											async beforeLoad(request)
											{ 
												if (this.reason_isMyProfile)
												{
													request.reConstruct(request.constructor.METHOD_GET, "clients/profile");
													request.needsAccessToken = true;
												}
											}
									*/
									
									try       { model = await B_REST_Model.commonDefs_load_one(this.mixinOptions.modelName,options); }
									catch (e) { this.throwEx(`Caught exception while trying to load model`,e);                       }
								}
							}
						}
						
						this.model = model;
						if (this.model && this.mixinOptions.modelReady) { this.mixinOptions.modelReady.call(this); }
						
						this._checkAutoUpdateInterval_needs(/*forceRemoval*/false);
					},
					_checkAutoUpdateInterval_needs(forceRemoval)
					{
						if (!this.mixinOptions.autoUpdateInterval) { return; }
						
						if (forceRemoval && this.autoUpdateInterval_ptr)
						{
							this.coreMixin_clearInterval(this.autoUpdateInterval_ptr);
							this.autoUpdateInterval_ptr = null;
						}
						
						if (this.model_has && !this.model_isNew)
						{
							B_REST_Utils.throwEx(`Refactor so we have a beforeDestroy() to rem that, otherwise it'll keep on try to save even if we go somewhere else`);
							
							this.autoUpdateInterval_ptr = this.coreMixin_setInterval(() =>
							{
								/*
								IMPORTANT:
									B_REST_Model::validation_isValid() doesn't wait for validation_custom_asyncFunc_x() to be ran,
									so it might say it's valid for now but might not be true in a few secs (ex validating user/email unicity)
									That means B_REST_Model::awaitUnsavedChangesSaved() could break (or for other reasons too anyway)
								*/
								
								if (this.shouldSavingBeEnabled) { this.awaitUnsavedChangesSaved(); }
							}, this.mixinOptions.autoUpdateInterval);
						}
					},
					//Especially for when used via BrGenericListBase::openFormInVDialog()
					close() { this.$emit("close"); },
					/*
					Rets if we had something to save, and throws on err
					If already saving, will just wait until ongoing save is done and return its promise
					Waits for ongoing things in the model, ex B_REST_ModelField_File uploads in progress
					WARNING:
						In implementation, always use "this.awaitUnsavedChangesSaved()" and not "this.model.awaitUnsavedChangesSaved()", because we would bypass stuff we do here
					*/
					async awaitUnsavedChangesSaved() { return this._awaitUnsavedChangesSaved(/*filesOnly*/false); },
					//Helper so that when we can't save ex due to validation errs, we don't trigger app's global unhandled Promise rejections handler to toast a warning msg to the user
					async awaitUnsavedChangesSaved_alwaysResolve()
					{
						try       { await this._awaitUnsavedChangesSaved(/*filesOnly*/false); }
						catch (e) { }
					},
					async awaitUnsavedChangesSaved_filesOnly()
					{
						if (this.model_isNew) { this._throwEx(`Must already exist to save files changes`); } //NOTE: Will throw if !isAutoInc; but we don't really handle that yet
						
						return this._awaitUnsavedChangesSaved(/*filesOnly*/true);
					},
						async _awaitUnsavedChangesSaved(filesOnly)
						{
							if (this.savePromise) { return this.savePromise; }
							
							if (!this.apiBaseUrl_has)       { this.throwEx(`Model doesn't have an apiBaseUrl, so we can't save automatically`); }
							if (!this.descriptor_isAutoInc) { this.throwEx(`Not yet supporting models that have no AUTO_INC / multi-field PK`); } //WARNING: Impacts in model_isNew() & shouldSavingBeEnabled() too
							if (!this.model_has)            { this.throwEx(`Can't save because model isn't instantiated yet`);                  }
							if (!this.descriptor_isAutoInc) { this.throwEx(`Can't save this model; check awaitUnsavedChangesSaved()`);          }
							
							let promise_s = null;
							let promise_f = null;
							const savePromise = new Promise((s,f) =>
							{
								promise_s = s;
								promise_f = f;
							});
							this.savePromise = savePromise;
							
							const finalize = (success, ifSuccess_savedSomething=null, ifError_subLocPath=null,ifError_details=null) =>
							{
								if (success)
								{
									if (ifSuccess_savedSomething) { this.$bREST.notifs_tmp({msg:this.t_alt(`save.success`),color:"success"}); }
									
									promise_s(ifSuccess_savedSomething);
									
									//For when we're from BrGenericListBase::openFormInVDialog(), to call each time we save. Likely to cause form to get destroyed right after
									if (this.parent_saveCallback) { this.parent_saveCallback(this.model); }
								}
								else
								{
									if (ifError_details) { B_REST_Utils.console_error(`Caught err in save`, ifError_details); }
									
									const msg = this.t_alt(`save.${ifError_subLocPath}`, ifError_details);
									this.$bREST.notifs_tmp({msg,color:"error"});
									
									promise_f(msg);
								}
								
								this.savePromise = null;
							};
							
							const wasNew = this.model_isNew;
							
							try
							{
								//Unless we only care about applying B_REST_ModelField_File changes, make sure all is ok. We must recall all async validation
								if (!filesOnly)
								{
									await this.model.validation_custom_recalc_wait(/*recurseUp*/false, /*recurseDown*/true); //Should never throw
									
									if (this.mixinOptions.customValidator) { await this.mixinOptions.customValidator.call(this); }
									
									const errMsgs = this.model_validationErrors;
									if (errMsgs.length>0)
									{
										finalize(false, null, "invalidData", {errMsgsJoined:`\t${errMsgs.join("\n\t")}`});
										return savePromise;
									}
								}
							
								const options = {
									apiBaseUrl_needsAccessToken: this.needsAccessToken,
									beforeSave:                  this.mixinOptions.beforeSave ? async(request,model)                  =>this.mixinOptions.beforeSave.call(this,request,model)                   : null, //Must do that if we want to have "this" working in the callbacks
									afterSave:                   this.mixinOptions.afterSave  ? async(response,model,isSuccess,wasNew)=>this.mixinOptions.afterSave.call( this,response,model,isSuccess,wasNew) : null, //Must do that if we want to have "this" working in the callbacks
								};
								
								if (wasNew || this.model.pk_tag===null) { options.apiBaseUrl=this.apiBaseUrl_post; }
								else
								{
									options.apiBaseUrl = this.apiBaseUrl_pk; //Ex "/clients/{pkTag}"
									
									options.apiBaseUrl_path_vars = {};
									options.apiBaseUrl_path_vars[B_REST_Model.API_PATH_VARS_PK_TAG] = this.model.pk_tag;
								}
								
								/*
								NOTE: In case usage wants to change API base URL, when just mixinOptions.apiBaseUrl etc isn't enough:
									Ex we want to reuse a <client-form> in a MyProfile.vue, and we want all "/clients/<*|pkTag>" calls to become "/myProfile" w no pkTag
									Use beforeSave() mixin instead, ex:
										async beforeSave(request,model)
										{ 
											if (this.reason_isAccountCreation)
											{
												request.reConstruct(request.constructor.METHOD_POST, "clients/profile");
												request.needsAccessToken_setDont();
											}
											else if (this.reason_isMyProfile)
											{
												request.reConstruct(request.constructor.METHOD_PATCH, "clients/profile");
												request.needsAccessToken = true;
											}
										}
								*/
								
								const methodName     = filesOnly ? "awaitUnsavedChangesSaved_filesOnly" : "awaitUnsavedChangesSaved";
								const savedSomething = await this.model[methodName](options); //Throws on err
								
								if (!filesOnly) { this.model.userTouch_toggleAllFields(false); } //WARNING: Actually we should have a B_REST_Model::userTouch_toggleAllFields_filesOnly()
								
								if (wasNew)
								{
									finalize(true, savedSomething, null,null); //NOTE: Should do this before afterSave_updateUrl(), otherwise we might miss the success popup
									
									if (this.isCurrentRouteMainComponent) { this.afterSave_updateUrl(); }
									this._checkAutoUpdateInterval_needs(/*forceRemoval*/false);
								}
								else { finalize(true, savedSomething, null,null); }
							}
							catch (e)
							{
								finalize(false, null, "unknownError",{e});
							}
							
							return savePromise;
						},
							/*
							Ex we were on "/citizens/*" and we should switch to the 123 we've just created, as "/citizens/123"
							WARNING:
								Only makes sense if module was created w B_REST_VueApp_base::helper_makeAuthenticatedModulesRoutes()
							*/
							async afterSave_updateUrl()
							{
								const partialVueRouterInfo = {params:{}};
								
								//NOTE: The following hack will keep all pathVars identical, expect will alter only one that we choose here
								{
									const pathVarNames_pkTag = this.$bREST.routes_current_def.pathVarNames_sub_pkTag || this.$bREST.routes_current_def.pathVarNames_pkTag || this.throwEx(`pathVarNames_pkTag should be defined in the current route def for this form`,this.$bREST.routes_current_def);
									
									partialVueRouterInfo.params[pathVarNames_pkTag] = this.model_pkTag;
								}
								
								return this.$bREST.routes_silentRouteUpdate(partialVueRouterInfo); //Merge against current route
							},
					async del() //NOTE: "delete" is a keyword D:
					{
						alert("genericDelete");
					},
					todos_getClasses(todo)
					{
						return [
							"todo",
							todo.isBug  ? "todo--is-bug"  : "todo--is-not-bug",
							todo.isDone ? "todo--is-done" : "todo--is-not-done",
						];
					},
				},
			},
		];
	};
	
	/*
	Usage ex:
		//From dynamic import func
		const someComponent = () => import("./someComponent.vue");
		const isGenericForm = await B_REST_Vuetify_GenericFormBase_isDerivedComponentImport(someComponent);
		
		//From static import
		import SomeComponent from "./SomeComponent.vue";
		const isGenericForm = await B_REST_Vuetify_GenericFormBase_isDerivedComponentImport(SomeComponent);
	NOTE: Doing this 100 times won't slow down the app or cause mem leak. Vue/Webpack will ret the same obj ptr
	*/
	export async function B_REST_Vuetify_GenericFormBase_isDerivedComponentImport(dynamicImportFunc_or_staticImport)
	{
		return B_REST_Vuetify_GenericFormBase_getMixinOptions(dynamicImportFunc_or_staticImport)!==null;
	};
		//If component is a BrGenericFormBase der, rets what was passed to B_REST_Vuetify_GenericFormBase_createMixin()
		export async function B_REST_Vuetify_GenericFormBase_getMixinOptions(dynamicImportFunc_or_staticImport)
		{
			const options = await B_REST_VueApp_base.getVueComponentOptions(dynamicImportFunc_or_staticImport);
			return options.mixins?.length===2 && options.mixins[1].isGenericFormBase===true ? options.mixins[1] : null;
		};
	
</script>

<style scoped>
	
	.todos-container {}
		.todo {
			list-style-position: outside;
			padding: 6px 6px 0 12px;
		}
			.todo--is-bug      { list-style: "🐞";              }
			.todo--is-not-bug  { list-style: "✨";              }
			.todo--is-done     { text-decoration: line-through; }
			.todo--is-not-done {                                }
	
</style>